import React from 'react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from '@material-ui/core';

const labelStyle = {
  fontSize: '12px',
  color: 'grey',
  cursor: 'pointer',
};

export default function SftpPickUp({
  formValues,
  setFormValues,
  originalFormValues,
  setUpdatingBlocks,
  updatingBlocks,
  editId,
  isUpdating,
  onSftpConfigurationCancel,
  updateSftpConfiguration,
  handleInputChange,
}) {
  return (
    <Card className='dashboardCard table_card_height'>
      <CardBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className='subHeading'>SFTP Pick Up</h3>
          <Switch
            name='is_sftp_enabled'
            checked={formValues.is_sftp_enabled}
            disabled={!isUpdating('sftp-configuration')}
            onChange={(value) =>
              handleInputChange({
                target: {
                  name: 'is_sftp_enabled',
                  value: value.target.checked,
                },
              })
            }
          />
        </span>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              type='text'
              labelText='Folder location for file pickup'
              disabled={!isUpdating('sftp-configuration')}
              onChange={handleInputChange}
              value={formValues.file_source}
              name='file_source'
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              type='text'
              labelText='Folder location to move processed File'
              onChange={handleInputChange}
              disabled={!isUpdating('sftp-configuration')}
              value={formValues.file_destination}
              name='file_destination'
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              type='text'
              labelText='File extension that will be picked up'
              onChange={handleInputChange}
              disabled={!isUpdating('sftp-configuration')}
              value={formValues.sftp_file_extension}
              name='sftp_file_extension'
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: '100%',
              }}>
              <FormControl>
                <FormLabel
                  style={labelStyle}
                  id='demo-row-radio-buttons-group-label'>
                  Is the file encrypted?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='is_encrypted'
                  value={formValues.is_encrypted}
                  onChange={handleInputChange}>
                  <FormControlLabel
                    value='true'
                    disabled={!isUpdating('sftp-configuration')}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value='false'
                    disabled={!isUpdating('sftp-configuration')}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </GridItem>
        </GridContainer>
        {editId && !updatingBlocks.includes('sftp-configuration') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={() =>
                setUpdatingBlocks([...updatingBlocks, 'sftp-configuration'])
              }
              color='primary'>
              Edit
            </Button>
          </div>
        )}
        {updatingBlocks.includes('sftp-configuration') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={onSftpConfigurationCancel}
              color='danger'>
              Cancel
            </Button>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={updateSftpConfiguration}
              color='primary'>
              Save SFTP Configuration
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
