import React from 'react';
import { PictureAsPdf } from '@material-ui/icons';
import { API_BASE_URL } from 'config';
import { openPdf } from './ManageFiles';

const FileList = ({ files }) => {
  return (
    <div
      style={{
        gap: '20px',
        display: 'flex',
        flexWrap: 'wrap',
      }}>
      {files?.map((file, index) => (
        <div
          title={
            'File Name: ' +
            file.original_file_name +
            '\n' +
            ('Uploaded By: ' + file.uploaded_by)
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'space-between',
            padding: '10px',
            background: '#36454F',
            borderRadius: '5px',
            fontSize: '14px',
            color: 'white',
            position: 'relative',
            cursor: 'pointer',
          }}
          key={index}
          onClick={() =>
            openPdf(
              API_BASE_URL +
                '/getFile_facility?filePath=' +
                file.aws_key +
                '&view=inline',
            )
          }>
          <div>
            {file?.original_file_name?.length > 25
              ? `${file?.original_file_name?.substring(0, 25)}...`
              : file?.original_file_name}
          </div>
          <PictureAsPdf />
        </div>
      ))}
    </div>
  );
};

export default FileList;
