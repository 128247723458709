import React, { useEffect, useState } from 'react';

import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, PUBLIC_ASSETS_BASE_URL } from '../../config';

import axios from 'axios';
import { usePostHog } from 'posthog-js/react';

// core components
import GridItem from 'components/Grid/GridsItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { Modal } from '@mui/material';

import { toast } from 'react-toastify';
import { Box } from '@mui/system';

export default function Loginfacility() {
  const [email, setEmail] = useState('');

  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState('');
  const [openFacilityOptions, setOpenFacilityOptions] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [mfaSession, setMfaSession] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtp, setIsOtp] = useState(false);
  const [mfaChallenge, setMfaChallenge] = useState('');

  // otp: '',
  // isOtp: false,
  // mfaChallenge: '',

  const posthog = usePostHog();

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleOtp = (event) => {
    setOtp(event.target.value);
  };

  const login = (isResendCode) => {
    const formData = new FormData();
    formData.append('email', email.trim());
    formData.append('password', password);
    if (!isResendCode) {
      formData.append('otp', otp);
      formData.append('mfaSession', mfaSession);
      formData.append('mfaChallenge', mfaChallenge);
    }

    axios
      .post(API_BASE_URL + '/facility_login', formData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .then(async (res) => {
        if (res.data?.mfa) {
          setIsOtp(true);
          setMfaSession(res.data.mfaSession);
          setMfaChallenge(res.data.mfaChallenge);
          return;
        }
        localStorage['userPermissions'] = JSON.stringify(
          res?.data?.userPermissions,
        );

        if (res?.data?.resetToken) {
          localStorage.setItem('resetToken', res?.data?.resetToken);
          window.location.href = '/user/reset';
          return;
        }
        localStorage['userId'] = JSON.stringify(res?.data?.user?.id);

        localStorage.setItem('lastActivity', Date.now());

        posthog.group('organization', res.data.organization.id, {
          name: res.data.organization.name,
        });

        posthog.identify(res.data.user.id, {
          name: res.data.user.email,
          email: res.data.user.email,
          organization: res.data.organization.name,
        });

        posthog.capture('login');

        if (res?.data?.facilities.length > 1) {
          setOpenFacilityOptions(true);
          setFacilityOptions(res.data.facilities);
          return;
        }

        await setActiveFacility(res.data.facilities[0].id);

        localStorage.setItem('currentFacilityId', res.data.facilities[0].id);

        res.data.facilities[0].role = 'facility';

        let appState = {
          isLoggedIn: true,
          user: { ...res?.data?.facilities[0] },
          name: res?.data?.facilities[0].name,
        };

        localStorage['appState'] = JSON.stringify(appState);
        setRedirect(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errmsg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //console.log(err);
      });
  };

  const setActiveFacility = async (facility_id) => {
    try {
      const data = (
        await axios.post(API_BASE_URL + '/set-active-facility', {
          email: email,
          facility_id: facility_id,
        })
      ).data;
      posthog.group('organization', data.organization.id, {
        name: data.organization.name,
      });

      posthog.identify(localStorage.getItem('userId'), {
        name: data.email,
        email: data.email,
        organization: data.organization.name,
      });

      posthog.capture('select facility');

      const facility = facilityOptions.find(
        (facility) => facility.id === facility_id,
      );
      if (!facility) return;
      facility.role = 'facility';
      let appState = {
        isLoggedIn: true,
        user: facility,
        name: facility.name,
      };

      localStorage['appState'] = JSON.stringify(appState);
      localStorage.setItem('currentFacilityId', facility_id);

      setRedirect(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          login();
        }}>
        {redirect && <Redirect to='/facility/facilitydashboard'></Redirect>}
        <GridContainer>
          <GridItem xs={8} sm={8} md={6}>
            <Card>
              <CardHeader color='primary'>
                <h4>Facility Login</h4>
              </CardHeader>
              <CardBody>
                {!isOtp ? (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <CustomInput
                        labelText='Email'
                        id='heading'
                        name='heading'
                        onChange={handleEmail}
                        value={email}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        dataTestId='inputEmail'
                      />
                    </GridItem>
                    <GridItem xs={8} sm={8} md={6}>
                      <CustomInput
                        labelText='Password'
                        id='subheading'
                        type='password'
                        name='subheading'
                        onChange={handlePassword}
                        value={password}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        dataTestId='inputPassword'
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <div>
                        <CustomInput
                          labelText='OTP'
                          onChange={handleOtp}
                          value={otp}
                          type='text'
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />

                        <p
                          style={{
                            fontSize: '14px',
                            marginBottom: '20px',
                            color: 'grey',
                          }}>
                          {mfaChallenge === 'EMAIL_OTP'
                            ? 'Please check your email for the one time passcode'
                            : 'Please enter your one time passcode from your authentication app'}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={6}></GridItem>
                  </GridContainer>
                )}
                <GridContainer alignItems='center'>
                  <GridItem xs={8} sm={8} md={6}>
                    <Button
                      color='primary'
                      type='submit'
                      dataTestId='loginButton'>
                      Login
                    </Button>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    {isOtp ? (
                      mfaChallenge === 'EMAIL_OTP' && (
                        <Link color='primary' onClick={() => login(true)}>
                          Resend OTP
                        </Link>
                      )
                    ) : (
                      <Link color='primary' to='/user/forgotpassword'>
                        Forgot Password?
                      </Link>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
      <Modal
        open={openFacilityOptions}
        onClose={() => setOpenFacilityOptions(false)}
        scroll='body'
        aria-labelledby='form-dialog-title'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          style={{
            height: 'max-content',
            padding: '30px',
            width: '70vw',
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            margin: 'auto',
          }}>
          <CardHeader
            color='primary'
            className='card-header'
            style={{ transform: 'translateY(-50%)' }}>
            <h4 className='card-title'>Available Facilities</h4>
          </CardHeader>
          <CardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              height: '100%',
              overflowY: 'scroll',
              marginTop: '-20px',
            }}>
            <p style={{ fontSize: '14px' }}>
              You have access to the below facilities. Click on the facility you
              want to be directed to:
            </p>
            <main
              style={{
                width: '850px',
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                paddingTop: '20px',
                margin: 'auto',
                display: 'flex',
                gap: '30px',
              }}>
              {facilityOptions.map((facility) => {
                return (
                  <div
                    onClick={() => setActiveFacility(facility.id)}
                    key={facility.id}
                    style={{
                      width: '250px',
                      cursor: 'pointer',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                      borderRadius: '5px',
                    }}>
                    <img
                      style={{
                        objectFit: 'fit',
                        width: '100%',
                        height: '115px',
                        padding: '10px',
                        borderRadius: '14px',
                      }}
                      src={PUBLIC_ASSETS_BASE_URL + facility.image_upload}
                      alt='logo'
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        marginBottom: '12px',
                        // fontWeight: 'bold',
                      }}>
                      {facility.name}
                    </p>
                  </div>
                );
              })}
            </main>
          </CardBody>
          {/* <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              className='email-box-header'>
              <h4>
                <strong>
                  You have access to the below facilities. Click on the
                  facility you want to be directed to:
                </strong>
              </h4>
            </div>
          </div> */}
        </Box>
      </Modal>
    </div>
  );
}
