import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
// core components
import GridItem from 'components/Grid/GridsItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import avatar from 'assets/img/faces/marc.jpg';
import logoimg from 'assets/img/imgpsh_fullsize_anim_front.png';
import { toast } from 'react-toastify';

const md5 = require('md5');
const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      template_name: '',
      heading: '',
      subheading: '',
      contact_number: '',
      message: '',
      selectedTemplateimage: null,
      template_image: '',
      logo_image: '',
      selectedLogoimage: null,
      background_image: '',
      selectedBackgroundImage: null,
      redirect: false,
      dellogo: false,
      password: '',
      otp: '',
      isOtp: false,
      mfaSession: '',
      mfaChallenge: '',
      isLoggedIn: false,
      user: {},
    };
    this.handlePassword = this.handlePassword.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleTempName = this.handleTempName.bind(this);
    this.handleHeading = this.handleHeading.bind(this);
    this.handleSubheading = this.handleSubheading.bind(this);
    this.handleContactNum = this.handleContactNum.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }
  deletelogo() {
    this.setState({ dellogo: true });
  }
  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleOtp = (event) => {
    this.setState({ otp: event.target.value });
  };

  logindashboard = (e) => {
    e.preventDefault();
    /*const requestOptions = {
			body: JSON.stringify({ email: this.state.email, password: md5(this.state.password) })
		};*/
    const email = this.state.email;
    const formData = new FormData();
    formData.append('email', email.trim());
    formData.append('password', this.state.password);
    formData.append('otp', this.state.otp);
    formData.append('mfaSession', this.state.mfaSession);
    formData.append('mfaChallenge', this.state.mfaChallenge);

    axios
      .post(API_BASE_URL + '/users/login', formData)
      .then((response) => {
        return response;
      })
      .then((res) => {
        if (res.data?.mfa) {
          this.setState({ isOtp: true, mfaSession: res.data.mfaSession, mfaChallenge: res.data.mfaChallenge });
        }
        if (res?.data?.resetToken) {
          localStorage.setItem('resetToken', res?.data?.resetToken);
          window.location.href = '/user/reset';
          return;
        }
        if (res?.data?.data) {
          let appState = {
            isLoggedIn: true,
            user: res && res.data && res.data.data,
          };
          localStorage['appState'] = JSON.stringify(appState);
          console.log(appState);
          this.setState({ redirect: true });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errmsg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //console.log("dfdf", err);
      });
  };

  /*** functions to set input values in state***/

  handleTempName(event) {
    this.setState({ template_name: event.target.value });
  }
  handleHeading(event) {
    this.setState({ heading: event.target.value });
  }
  handleSubheading(event) {
    this.setState({ subheading: event.target.value });
  }
  handleContactNum(event) {
    this.setState({ contact_number: event.target.value });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }

  onTempFileChange = (event) => {
    this.setState({ selectedTemplateimage: event.target.files[0] });
  };

  onLogoFileChange = (event) => {
    this.setState({ selectedLogoimage: event.target.files[0] });
  };

  onBackgroundFileChange = (event) => {
    this.setState({ selectedBackgroundImage: event.target.files[0] });
  };

  /*** function to integrate api to create template*/

  createtemplate = () => {
    var formData = new FormData();
    if (this.state.template_name) {
      formData.append('template_name', this.state.template_name);
    }
    if (this.state.heading) {
      formData.append('heading', this.state.heading);
    }
    if (this.state.name) {
      formData.append('name', this.state.name);
    }
    if (this.state.email) {
      formData.append('email', this.state.email);
    }
    if (this.state.contact_number) {
      formData.append('contact_number', this.state.contact_number);
    }
    if (this.state.message) {
      formData.append('message', this.state.message);
    }
    if (this.state.subheading) {
      formData.append('subheading', this.state.subheading);
    }
    if (this.state.selectedTemplateimage) {
      formData.append('template_image', this.state.selectedTemplateimage);
    }
    if (this.state.selectedLogoimage) {
      formData.append('logo_image', this.state.selectedLogoimage);
    }
    if (this.state.selectedBackgroundImage) {
      formData.append('background_image', this.state.selectedBackgroundImage);
    }

    axios
      .post(API_BASE_URL + '/api/createtemplate', formData)
      .then((response) => {
        return response;
      })
      .then((res) => {
        this.setState({ redirect: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {}

  render() {
    return (
      <form onSubmit={this.logindashboard}>
        {this.state.redirect && <Redirect to='/admin/dashboard'></Redirect>}
        <GridContainer>
          <GridItem xs={12} sm={10} md={6} id='10'>
            <Card>
              <CardHeader color='primary'>
                <GridContainer alignItems='center' justify='space-between'>
                  <GridItem xs={8} sm={8} md={6} className='mx-auto'>
                    <h4>Admin Login</h4>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <img src={logoimg} width='130' />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {!this.state.isOtp ? (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <CustomInput
                        labelText='Email'
                        id='heading'
                        name='heading'
                        onChange={this.handleEmail}
                        value={this.state.email}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        dataTestId='inputEmail'
                      />
                    </GridItem>
                    <GridItem xs={8} sm={8} md={6}>
                      <CustomInput
                        labelText='Password'
                        id='subheading'
                        type='password'
                        name='subheading'
                        onChange={this.handlePassword}
                        value={this.state.password}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        dataTestId='inputPassword'
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <CustomInput
                        labelText='OTP'
                        onChange={this.handleOtp}
                        value={this.state.otp}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={8} sm={8} md={6}></GridItem>
                  </GridContainer>
                )}
                <GridContainer alignItems='center'>
                  <GridItem xs={8} sm={8} md={6}>
                    <Button
                      color='primary'
                      type='submit'
                      dataTestId='loginButton'>
                      Login
                    </Button>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    {this.state.isOtp ? (
                      <Link color='primary' onClick={this.logindashboard}>
                        Resend OTP
                      </Link>
                    ) : (
                      <Link color='primary' to='/user/forgotpassword'>
                        Forgot Password?
                      </Link>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}