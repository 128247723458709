import { makeStyles } from '@material-ui/core/styles';

export const useStylesDanger = makeStyles({
  root: {
    // input label when focused
    '& label.Mui-focused': {
      color: 'red',
    },

    // focused color for input with variant='standard'
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    // focused color for input with variant='filled'
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },

    '& .MuiOutlinedInput-root .danger': {
      borderColor: 'red',
    },
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
});
