import { Box } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Chip, Modal, Switch } from '@mui/material'
import axios from 'axios'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import GridsItem from 'components/Grid/GridsItem'
import { API_BASE_URL } from 'config'
import { useEffect, useState } from 'react'
import { modalStyle } from 'views/Clients/Claim'

const AdminUserManagement = () => {
  const [open, setOpen] = useState(false)
  const [modalErr, setModalErr] = useState(false)
  const [editUser, setEditUser] = useState(null)
  const [adminUsers, setAdminUsers] = useState([])

  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    name: '',
    status: '',
    enabled: ''
  })

  const onInputChange = (e, name) => {
    setInputs({ ...inputs, [name]: e.target.value })
  }

  const openAddAdminUserModal = () => {
    setOpen(true)
  }

  const addUser = async (e) => {
    try {
      e.preventDefault()
      await axios.post(API_BASE_URL + '/admin/user', {
        email: inputs.email,
        name: inputs.name,
      })

      getUsers()
      closeModal()
    } catch (err) {
      console.log(err)
      setModalErr(err.response?.data?.message)
    }
  }

  const updateUser = async (e) => {
    try {
      e.preventDefault()
      await axios.put(API_BASE_URL + '/admin/user', {
        ...inputs
      })
      getUsers()
      closeModal()
    } catch (err) {
      console.log(err)
      setModalErr(err.response?.data?.message)
    }
  }

  const deleteUser = async (e) => {
    try {
      e.preventDefault()
      await axios.delete(API_BASE_URL + `/admin/user/${inputs.username}`)
      getUsers()
      closeModal()
    } catch (err) {
      console.log(err)
      setModalErr(err.response?.data?.message)
    }
  }

  const resendActivationEmail = async (e) => {
    try {
      e.preventDefault()
      await axios.post(API_BASE_URL + `/admin/user/resend_activation_email`, {
        name: inputs.name,
        email: inputs.email,
        username: inputs.username,
      })
      closeModal()
    } catch (err) {
      console.log(err)
      setModalErr(err.response?.data?.message)
    }
  }

  const toggleUser = async (username, deactivateUser) => {
    try {
      alert(
        `Are you sure you want to ${deactivateUser ? 'DEACTIVATE' : 'ACTIVATE'
        } this user?`
      )

      await axios.put(API_BASE_URL + '/admin/user/toggle', {
        username,
        deactivateUser,
      })
      getUsers()
    } catch (err) {
      console.log(err)
    }
  }

  const getUsers = async () => {
    try {
      const response = await axios.get(API_BASE_URL + '/admin/users')
      setAdminUsers(response.data ?? [])
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])


  const updateUserData = async (user) => {
    try {
      setEditUser(true)
      setInputs({
        username: user.username,
        email: user.email,
        name: user.name,
        status: user.status,
        enabled: user.enabled
      })
    } catch (err) {
      console.log(err)
    }
  }

  const closeModal = () => {
    setOpen(false)
    setEditUser(null)
    setModalErr('')
    setInputs({
      username: '',
      email: '',
      name: '',
    })
  }

  return (
    <GridContainer>
      <GridsItem xs={12} sm={12} md={12}>
        <h2 className='subHeading'>Manage Admin Users</h2>{' '}
      </GridsItem>
      <Card className='dashboardCard table_card_height'>
        <CardHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              direction: 'row-reverse',
            }}>
            <Button
              color='primary'
              onClick={openAddAdminUserModal}
              style={{ height: '40px' }}>
              Add admin user
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <table width='100%' id='template' className='paymentTable'>
            <thead>
              <tr>
                <th width='5%'>S.no</th>
                <th>Email</th>
                <th>Email verified</th>
                <th>Confirmation status</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {adminUsers.map((user, index) => {
                return (
                  <tr
                    key={user.username}
                    style={{ color: user.enabled === 'true' ? 'grey' : null }}>
                    <td><b>{index + 1}</b></td>
                    <td><b>{user.email}</b></td>
                    <td><Chip label={user.emailVerified} style={{ fontWeight: 600 }} color={user.emailVerified !== 'true' ? "error" : "success"} /></td>
                    <td><Chip label={user.status.toLowerCase()} style={{ fontWeight: 600 }} color={user.status !== 'CONFIRMED' ? "error" : "success"} /></td>
                    <td><Chip label={user.enabled === 'true' ? 'Activated' : 'Deactivated'} style={{ fontWeight: 600 }} color={user.enabled === 'true' ? "success" : "error"} /></td>
                    <td>
                      <div
                        style={{
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}>
                        <Switch
                          checked={user.enabled === 'true' ? true : false}
                          onChange={() =>
                            toggleUser(
                              user.username,
                              user.enabled === 'true' ? true : false
                            )
                          }
                        />
                        <Edit
                          onClick={() => updateUserData(user)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>

      <Modal open={(editUser ? true : false) || open} onClose={closeModal}>
        <form onSubmit={editUser ? updateUser : addUser}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              {editUser ? <h4>Edit user</h4> : <h4>Add admin user</h4>}
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    labelText='Name'
                    onChange={(e) => onInputChange(e, 'name')}
                    value={inputs.name}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='Email'
                    onChange={(e) => onInputChange(e, 'email')}
                    value={inputs.email}
                    disabled={editUser ? true : false}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <main
                  style={{
                    padding: '20px',
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}>
                </main>
              </GridContainer>
              <div>
                <GridContainer>
                  <Button variant='contained' color='primary' type='submit' style={{ margin: '5px 20px' }}>
                    {editUser ? 'Update' : 'Add'}
                  </Button>
                  {editUser && inputs.enabled === 'true' && (
                    <Button variant='contained' color='warning' onClick={resendActivationEmail} style={{ margin: '5px 20px 5px 0px' }}>
                      Resend activation email
                    </Button>
                  )}
                  {editUser && (
                    <Button variant='contained' color='danger' onClick={deleteUser} >
                      Delete
                    </Button>
                  )}
                </GridContainer>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
    </GridContainer>
  )
}

export default AdminUserManagement