import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { EditRounded } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button';
import ManageFiles from './ManageFiles';
import { modalStyle } from 'views/Clients/Claim';
import { updateRefund } from './UpdateRefundModal';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { REFUND_STATUSES } from './FacilityTransaction/FacilityTransaction';

export default function IssueCheckModal({
  currentCheckRefund,
  setCurrentCheckRefund,
  setCurrentInfo,
  setOriginalInfo,
  setModalErr,
  processPayment,
  role,
  setLoading,
}) {
  const [addFiles, setAddFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const onIssueCheck = async () => {
    try {
      setLoading(true);
      if (addFiles.length || deletedFiles.length) {
        await updateRefund({
          id: currentCheckRefund.id,
          deletedFiles,
          files: addFiles,
          role,
        });
      }
      await processPayment(currentCheckRefund.id);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setModalErr(err.response?.data?.message);
    }
  };

  return (
    <Modal
      open={!!currentCheckRefund}
      onClose={() => setCurrentCheckRefund(null)}>
      <Box
        sx={modalStyle}
        className='makeStyles-card-96'
        noValidate
        autoComplete='off'>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <h4>Process Payment</h4>
        </CardHeader>
        <CardBody>
          {currentCheckRefund.refund_type === 'insurance' &&
            currentCheckRefund.refund_status === REFUND_STATUSES.EXCEPTION &&
            !addFiles.length && (
              <p
                style={{
                  color: 'red',
                  padding: '0',
                  margin: 0,
                  fontSize: '15px',
                  marginBottom: '20px',
                }}>
                No documents attached if you would still like to proceed please
                click confirm and issue the check.
              </p>
            )}

          {currentCheckRefund && (
            <div>
              <p style={{ marginBottom: '7px', fontWeight: 'bold' }}>
                Address on file:{' '}
                <a
                  onClick={() => {
                    setCurrentInfo(currentCheckRefund);
                    setOriginalInfo(currentCheckRefund);
                    setModalErr('');
                  }}>
                  <EditRounded style={{ cursor: 'pointer' }} fontSize='small' />
                </a>
              </p>
              <p style={{ marginBottom: '7px' }} className='page-desc'>
                {currentCheckRefund.refund_to_fullname}
              </p>
              <p style={{ marginBottom: '7px' }} className='page-desc'>
                {currentCheckRefund.refund_to_address_line1},{' '}
                {currentCheckRefund.refund_to_address_line2}
              </p>
              <p style={{ marginBottom: '7px' }} className='page-desc'>
                {currentCheckRefund.refund_to_city},{' '}
                {currentCheckRefund.refund_to_state},{' '}
                {currentCheckRefund.refund_to_zip}{' '}
              </p>
            </div>
          )}

          {currentCheckRefund.refund_type === 'insurance' && (
            <ManageFiles
              addFiles={addFiles}
              setAddFiles={setAddFiles}
              refund={currentCheckRefund}
              setDeletedFiles={setDeletedFiles}
              deletedFiles={deletedFiles}
              role={role}
            />
          )}
          {!!addFiles.length && (
            <p
              style={{
                fontSize: '15px',
                marginTop: '20px',
                fontWeight: 'bold',
              }}>
              *By clicking "Issue check" you are issuing a check and attaching
              the above documents.
            </p>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '30px',
            }}>
            <Button
              onClick={onIssueCheck}
              type='button'
              variant='contained'
              color='rose'>
              Issue check
            </Button>
          </div>
        </CardBody>
      </Box>
    </Modal>
  );
}
