/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import Payment from '@material-ui/icons/Payment';
import Business from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import BubbleChart from '@material-ui/icons/BubbleChart';
import LocationOn from '@material-ui/icons/LocationOn';
import Notifications from '@material-ui/icons/Notifications';
import Unarchive from '@material-ui/icons/Unarchive';
import Language from '@material-ui/icons/Language';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard.js';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import { Icon, InlineIcon } from '@iconify/react';
import charityIcon from '@iconify-icons/mdi/charity';

import UserProfile from 'views/UserProfile/UserProfile.js';
import Login from 'views/UserProfile/Login.js';
import Client from 'views/Clients/Client.js';
import Clients from 'views/Clients/Clients.js';
import Thankyou from 'views/Clients/Thankyou.js';
import Error from 'views/Clients/Error.js';
import Refunds from 'views/Refunds/Refunds.js';
import Payments from 'views/Payments/Payments.js';
import PaymentMethod from 'views/Clients/payment-method.js';
import ChequeMethod from 'views/Clients/cheque-method.js';
import AmazonGiftCard from 'views/Clients/amazon-giftcard.js';

import Loginfacility from 'views/UserProfile/Loginfacility.js';
import Resetpassword from 'views/UserProfile/Resetpassword.js';
import ForgotPassword from 'views/UserProfile/ForgotPassword.js';
import TableList from 'views/TableList/TableList.js';
//import ManageTemplate from "views/TableList/ManageTemplate.js";
//import Updatetemplate from "views/UserProfile/Updatetemplate.js";
import ManageTemplate from 'views/Templates/ManageTemplate.js';
import Facilities from 'views/Facilities/Facilities.js';
import Subfacilities from 'views/Subfacility/Subfacilities.js';
import Charities from 'views/Charities/Charities.js';
import AmazonTransactions from 'views/AmazonTransactions/AmazonTransactions.js';
import CreateFacility from 'views/Facilities/CreateFacility.js';
import UpdateFacility from 'views/Facilities/UpdateFacility.js';
import CreateSubfacility from 'views/Subfacility/CreateSubfacility.js';
import UpdateSubfacility from 'views/Subfacility/UpdateSubfacility.js';

import CreateCharity from 'views/Charities/CreateCharity';
import UpdateCharity from 'views/Charities/UpdateCharity';
import UnsubscribeEmail from 'views/Clients/UnsubscribeEmail';
import NewUserReset from 'views/NewUserReset';
import UploadedFiles from 'views/Dashboard/UploadedFiles';
import {
  CloudDownload,
  CloudDownloadRounded,
  MonetizationOn,
  PersonAdd,
} from '@material-ui/icons';
import UserManagement from 'views/Facilities/UserManagement';
import AdminUserManagement from 'views/Admin/AdminUserManagement';
import AchPayments from 'views/Dashboard/Achpayments';
import ACHFiles from 'views/Dashboard/ACHFiles';
import AdminReport from 'views/Managefile/AdminReporting';
import CreateTangoGroup from 'views/Facilities/CreateTangoGroup';
import CreateTangoAccount from 'views/Facilities/CreateTangoAccount';
import TangoPage from 'views/Facilities/TangoPage';
import ChoosePaymentClient from 'views/Clients/ChoosePaymentClient/ChoosePaymentClient';
import MoovPage from 'views/Facilities/MoovPage';
import CreateMoovAccount from 'views/Facilities/CreateMoovAccount';
import AddMoovPaymentMethod from 'views/Facilities/AddMoovPaymentMethod';
import ActivateUser from 'views/UserProfile/ActivateUser';
import FacilityTransaction  from 'views/Clients/FacilityTransaction';
import ReconProcessing from 'views/Clients/recon-processing';
import Organizations from 'views/Organizations/Organizations';
import CreateOrganization from 'views/Organizations/CreateOrganization';
import UpdateOrganization from 'views/Organizations/UpdateOrganization';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'قائمة الجدول',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/adminUserManagement',
    name: 'Manage Admin Users',
    rtlName: 'قائمة الجدول',
    icon: PersonAdd,
    component: AdminUserManagement,
    layout: '/admin',
  },
  {
    path: '/organizations',
    name: 'Organizations',
    rtlName: '',
    icon: Business,
    component: Organizations,
    layout: '/admin',
  },
  {
    path: '/facilities',
    name: 'Facilities',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: Business,
    component: Facilities,
    layout: '/admin',
  },
  {
    path: '/subfacilities',
    name: 'Sub Facilities',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: ApartmentIcon,
    component: Subfacilities,
    layout: '/admin',
  },
  {
    path: '/usermanagement',
    name: 'Manage Facility Users',
    rtlName: 'قائمة الجدول',
    icon: PersonAdd,
    component: UserManagement,
    layout: '/admin',
  },
  {
    path: '/charities',
    name: 'Charities',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: NaturePeopleIcon,
    component: Charities,
    layout: '/admin',
  },
  {
    path: '/TangoPage',
    name: 'Manage Tango',
    rtlName: 'قائمة الجدول',
    icon: CardGiftcardIcon,
    component: TangoPage,
    layout: '/admin',
  },
  {
    path: '/MoovPage',
    name: 'Manage Moov',
    rtlName: 'قائمة الجدول',
    icon: MonetizationOn,
    component: MoovPage,
    layout: '/admin',
  },
  // {
  //   path: '/refunds',
  //   name: 'Refunds',
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   icon: Person,
  //   component: Refunds,
  //   layout: '/admin',
  // },
  {
    path: '/ach',
    name: 'ACH / Check Transactions',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: Person,
    component: AchPayments,
    layout: '/admin',
  },
  // {
  //   path: '/amazontransaction',
  //   name: 'Amazon Transaction',
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   icon: CardGiftcardIcon,
  //   component: AmazonTransactions,
  //   layout: '/admin',
  // },
  {
    path: '/reset',
    name: 'Amazon Transaction',
    rtlName: 'ملف تعريفي للمستخدم',
    // icon: CardGiftcardIcon,
    component: NewUserReset,
    layout: '/user',
    invisible: true,
  },
  {
    path: '/reconProcessing',
    name: 'Recon Processing',
    icon: CardGiftcardIcon,
    component: ReconProcessing,
    layout: '/admin',
  },
  {
    path: '/transaction',
    name: 'Transactions',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: Payment,
    component: () => (
      <>
        <FacilityTransaction role='admin' />
      </>
    ),
    layout: '/admin',
  },
  {
    path: '/uploadedrefundfile',
    name: 'Csv Files',
    rtlName: 'قائمة الجدول',
    icon: CloudUploadIcon,
    component: UploadedFiles,
    layout: '/admin',
  },
  {
    path: '/allachFiles',
    name: 'Ach / Positive Pay Files',
    rtlName: 'قائمة الجدول',
    icon: CloudUploadIcon,
    component: ACHFiles,
    layout: '/admin',
  },
  {
    path: '/reports',
    name: 'Reports',
    rtlName: 'قائمة الجدول',
    icon: CloudDownloadRounded,
    component: AdminReport,
    layout: '/admin',
  },
  {
    path: '/createFacility',
    name: '',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: CreateFacility,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/createSubfacility',
    name: '',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: CreateSubfacility,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/createcharity',
    name: '',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: CreateCharity,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/updatesubfacility/:id',
    name: 'Update Subfacility',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: UpdateSubfacility,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/updatefacility/:id',
    name: 'Update Facility',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: UpdateFacility,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/updatefacility/:id',
    name: 'Update Facility',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: UpdateFacility,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/updatecharity/:id',
    name: 'Update Charity',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: UpdateCharity,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/CreateMoovGroup',
    name: 'Update Charity',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: CreateMoovAccount,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/AddMoovPaymentMethod',
    name: 'Update Charity',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: AddMoovPaymentMethod,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/CreateTangoGroup',
    name: 'Update Charity',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: CreateTangoGroup,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/CreateTangoAccount',
    name: 'Update Charity',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: CreateTangoAccount,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/updatecharity/:id',
    name: 'Update Charity',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: UpdateCharity,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/login',
    name: 'Login',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Login,
    layout: '/user',
    invisible: true,
  },
  {
    path: '/clientdetail/:id',
    name: 'Client Authentication',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Client,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/unsubscribe-email/:id',
    name: 'unsubscribe email',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: UnsubscribeEmail,
    layout: '/user',
    invisible: true,
  },
  {
    path: '/clientdetails',
    name: 'Client Authentication',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Clients,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/choose-giftcards/:tangoType/:id',
    name: 'ChoosePayment',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: ChoosePaymentClient,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/choose-donations/:tangoType/:id',
    name: 'ChoosePayment',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: ChoosePaymentClient,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/paymentdetail/:id',
    name: 'Client Payments',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: PaymentMethod,
    layout: '/client',
    invisible: true,
  },

  {
    path: '/error',
    name: '404 Error',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Error,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Thankyou,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/chequedetail/:id',
    name: 'Client Payments',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: ChequeMethod,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/amazongiftcard/:id',
    name: 'Client Payments',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: AmazonGiftCard,
    layout: '/client',
    invisible: true,
  },
  {
    path: '/FacilityLogin',
    name: 'Facility Login',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Loginfacility,
    layout: '/user',
    invisible: true,
  },

  {
    path: '/forgotpassword',
    name: 'Forgot Password',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: ForgotPassword,
    layout: '/user',
    invisible: true,
  },
  {
    path: '/resetpassword/',
    name: 'Reset Password',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: Resetpassword,
    layout: '/user',
    invisible: true,
  },
  {
    path: '/activation/:encoded',
    name: 'User activation',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: ActivateUser,
    layout: '/user',
    invisible: true,
  },
  {
    path: '/createOrganization',
    name: '',
    rtlName: '',
    icon: 'content_paste',
    component: CreateOrganization,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/updateOrganization/:id',
    name: '',
    rtlName: '',
    icon: 'content_paste',
    component: UpdateOrganization,
    layout: '/admin',
    invisible: true,
  },

  /* {
     path: "/typography",
     name: "Typography",
     rtlName: "طباعة",
     icon: LibraryBooks,
     component: Typography,
     layout: "/admin"
   },
   {
     path: "/icons",
     name: "Icons",
     rtlName: "الرموز",
     icon: BubbleChart,
     component: Icons,
     layout: "/admin"
   },
   {
     path: "/maps",
     name: "Maps",
     rtlName: "خرائط",
     icon: LocationOn,
     component: Maps,
     layout: "/admin"
   },
   {
     path: "/notifications",
     name: "Notifications",
     rtlName: "إخطارات",
     icon: Notifications,
     component: NotificationsPage,
     layout: "/admin"
   },
   {
     path: "/rtl-page",
     name: "RTL Support",
     rtlName: "پشتیبانی از راست به چپ",
     icon: Language,
     component: RTLPage,
     layout: "/rtl"
   }*/
];

export default dashboardRoutes;
