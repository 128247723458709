import React, { useEffect } from 'react';
import { PictureAsPdf, Close } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button';
import { API_BASE_URL } from '../../config';
import axios from 'axios';
import { REFUND_STATUSES } from './FacilityTransaction/FacilityTransaction';
export const openPdf = (url) => {
  window.open(url, '_blank');
};

const ManageFiles = ({
  addFiles,
  setAddFiles,
  setDeletedFiles,
  deletedFiles,
  refund,
  role,
}) => {
  useEffect(async () => {
    const result = await axios.get(
      API_BASE_URL +
        `/refund-attachments${role === 'admin' ? '-admin' : ''}/` +
        refund.id,
    );

    const files = result.data?.map((file) => ({
      originalFileName: file.original_file_name,
      awsKey: file.aws_key,
      uploadedBy: file.uploaded_by,
      uploadedAt: file.uploaded_at,
      local: false,
    }));

    setAddFiles(files);
  }, []);

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files).map((file) => ({
      originalFileName: file.name,
      fileUrl: URL.createObjectURL(file),
      fileType: file.type,
      uploadedBy: 'You',
      file: file,
      local: true,
    }));
    setAddFiles((prevFiles) => {
      return [...prevFiles, ...uploadedFiles];
    });
  };

  const handleFileRemove = (index) => {
    const fileToRemove = addFiles[index];
    if (fileToRemove.local === true) {
      return setAddFiles((prevFiles) => {
        return prevFiles.filter((_, i) => i !== index);
      });
    }

    // also check aws key duplicates
    return setDeletedFiles((prevDeleted) => {
      const newDeleted = [...prevDeleted, fileToRemove.awsKey];

      const deletedSet = new Set(newDeleted);
      return [...deletedSet];
    });
  };

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '10px 0',
      }}>
      {addFiles.map((file, index) => {
        return (
          (!deletedFiles.includes(file.awsKey) || file.local) && (
            <div
              title={
                'File Name: ' +
                file.originalFileName +
                '\n' +
                ('Uploaded By: ' + file.uploadedBy)
              }
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'space-between',
                padding: '10px',
                background: '#36454F',
                borderRadius: '5px',
                fontSize: '14px',
                color: 'white',
                position: 'relative',
                cursor: 'pointer',
              }}
              key={index}
              onClick={() =>
                openPdf(
                  file.fileUrl ??
                    API_BASE_URL +
                      '/getFile_facility?filePath=' +
                      file.awsKey +
                      '&view=inline',
                )
              }>
              <div>
                {file.originalFileName.length > 25
                  ? `${file.originalFileName.substring(0, 25)}...`
                  : file.originalFileName}
              </div>
              <PictureAsPdf />
              {refund.refund_status === REFUND_STATUSES.EXCEPTION && (
                <div
                  title=''
                  style={{
                    background: '#e91e63',
                    zIndex: 10,
                    position: 'absolute',
                    top: '-10px',
                    right: '-10px',
                    cursor: 'pointer',
                    height: '22px',
                    width: '22px',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileRemove(index);
                  }}>
                  <Close style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
              )}
            </div>
          )
        );
      })}
      {refund.refund_status === REFUND_STATUSES.EXCEPTION && (
        <div
          style={{
            height: '40px',
          }}>
          <input
            type='file'
            accept='application/pdf'
            multiple
            style={{ display: 'none' }}
            id='file-upload'
            onChange={handleFileUpload}
          />
          <label htmlFor='file-upload'>
            <Button color='primary' component='span'>
              Upload Files
            </Button>
          </label>
        </div>
      )}
    </div>
  );
};

export default ManageFiles;
