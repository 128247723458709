import { useState, useEffect } from 'react';
import { DataMappingsApi } from '../../clients';

export const useDataMappingsForUpload = () => {
  const [dataMappingsForUpload, setDataMappingsNames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const response = await DataMappingsApi.getDataMappingsForUpload();
    setDataMappingsNames(response.data);
    setLoading(false);
  }, [loading]);

  return { dataMappingsForUpload, loading };
};
