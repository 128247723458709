import { usePostHog } from 'posthog-js/react';
import ManageDataMapping from '../ManageDataMapping';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { useEffect, useState } from 'react';
import loader from 'assets/img/loader.svg';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { TablePaginationActions } from 'views/Facilities/Facilities';
import moment from 'moment';
import { NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG, REFUND_TYPES } from 'utility';

export default function DataMappings() {
  const [loaded, setLoaded] = useState(false);
  const [dataMappings, setDataMappings] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const posthog = usePostHog();
  if (posthog.isFeatureEnabled(NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG)) {
    console.log('Feature is enabled');
  }

  const getDataMappings = async () => {
    try {
      const response = await axios.get(API_BASE_URL + '/data-mappings');
      setDataMappings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    getDataMappings();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteDataMapping = async (id) => {
    try {
      const isConfirmed = window.confirm(
        'Are you sure you want to delete this data mapping?',
      );
      if (!isConfirmed) {
        return;
      }
      await axios.delete(API_BASE_URL + '/data-mappings/' + id);
      const response = await axios.get(API_BASE_URL + '/data-mappings');
      await getDataMappings();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {!posthog.isFeatureEnabled(NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG) ? (
        <ManageDataMapping />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Data Mappings</h4>
                {/* {this.state.errmsg && (
                <p style={{ color: '#ff0000' }}>{this.state.errmsg}</p>
              )} */}
              </CardHeader>

              <CardBody>
                <div>
                  {loaded && (
                    <div className='loaders'>
                      <img src={loader} />
                    </div>
                  )}
                  <div className='t-header'>
                    <div className='t-header-right'>
                      <Link
                        to={`/facility/create-data-mapping`}
                        className='link'>
                        <span className='add-icon'>
                          <AddIcon />
                        </span>
                        Create Data Mapping
                      </Link>
                    </div>
                  </div>
                  {/* <table width="100%" id="template">
		  <tr><td> <Link to={`/admin/createFacility`}><span className="material-icons">
add
</span>Create Facility</Link> </td></tr>
		  </table> */}
                  <Table width='100%' id='template'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'>Data Mapping Name</TableCell>
                        <TableCell align='left'>Last Updated By</TableCell>
                        <TableCell align='left'>Last Updated At</TableCell>
                        <TableCell align='left'>File Delimiter</TableCell>
                        <TableCell align='left'>Contains</TableCell>
                        <TableCell align='left'>SFTP Enabled</TableCell>
                        <TableCell align='left'>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    {dataMappings.length ? (
                      <TableBody>
                        {(rowsPerPage > 0
                          ? dataMappings.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                          : dataMappings
                        ).map((dataMapping, index) => (
                          <TableRow className='txtalign' key={index}>
                            <TableCell>{dataMapping.name}</TableCell>
                            <TableCell>{dataMapping.updated_by}</TableCell>
                            <TableCell>
                              {moment(dataMapping.updated_at).format(
                                'MM/DD/YYYY',
                              )}
                            </TableCell>
                            <TableCell>{dataMapping.delimiter}</TableCell>
                            <TableCell>
                              {dataMapping.refund_types
                                .map((refundType) =>
                                  refundType === REFUND_TYPES.REGULAR
                                    ? 'Consumer Payments'
                                    : 'Insurance',
                                )
                                .join(', ')}
                            </TableCell>

                            <TableCell>
                              {dataMapping.is_sftp_enabled ? 'Yes' : 'No'}
                            </TableCell>

                            <TableCell>
                              <Link
                                to={`/facility/edit-data-mapping/${dataMapping.id}`}
                                title='Update Data Mapping'>
                                <span className='material-icons'>edit</span>
                              </Link>

                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  deleteDataMapping(dataMapping.id)
                                }
                                title='Delete DataMapping'>
                                {' '}
                                <span className='material-icons'>delete</span>
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell>No Data Mapping Data Found!</TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableFooter>
                      <TableRow align='center'>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: 'All', value: -1 },
                          ]}
                          colSpan={3}
                          count={dataMappings.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { roboto: 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
