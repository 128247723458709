import React from 'react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

const labelStyle = {
  fontSize: '12px',
  color: 'grey',
  cursor: 'pointer',
};

export default function FileConfiguration({
  formValues,
  setFormValues,
  originalFormValues,
  setUpdatingBlocks,
  updatingBlocks,
  editId,
  isUpdating,
  onFileConfigurationCancel,
  updateFileConfiguration,
  handleInputChange,
}) {
  return (
    <Card className='dashboardCard table_card_height'>
      <CardBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}>
        <h3 className='subHeading'>File Configuration</h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              type='text'
              labelText='Data Mapping Name'
              onChange={handleInputChange}
              value={formValues.name}
              name='name'
              disabled={!isUpdating('file-configuration')}
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className='custom-select'>
              <FormControl>
                <InputLabel id='demo-simple-select-label'>
                  File Delimiter
                </InputLabel>
                <Select
                  disabled={!isUpdating('file-configuration')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  name='delimiter'
                  value={formValues.delimiter}
                  onChange={handleInputChange}
                  className='full-width-elem'>
                  <MenuItem value={'comma'}>Comma</MenuItem>
                  <MenuItem value={'pipe'}>Pipe</MenuItem>
                  <MenuItem value={'tab'}>Tab</MenuItem>
                </Select>
              </FormControl>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: '100%',
              }}>
              <FormControl>
                <FormLabel
                  style={labelStyle}
                  id='demo-row-radio-buttons-group-label'>
                  Does the file contain a header with column names
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  value={formValues.contains_header}
                  onChange={handleInputChange}
                  name='contains_header'>
                  <FormControlLabel
                    value='true'
                    control={<Radio />}
                    label='Yes'
                    disabled={!isUpdating('file-configuration')}
                  />
                  <FormControlLabel
                    value='false'
                    control={<Radio />}
                    label='No'
                    disabled={!isUpdating('file-configuration')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText='How many rows need to be bypassed from the top of the file'
              onChange={handleInputChange}
              type='number'
              value={formValues.rows_to_skip}
              disabled={!isUpdating('file-configuration')}
              name='rows_to_skip'
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
        </GridContainer>
        {editId && !updatingBlocks.includes('file-configuration') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={() =>
                setUpdatingBlocks([...updatingBlocks, 'file-configuration'])
              }
              color='primary'>
              Edit
            </Button>
          </div>
        )}
        {updatingBlocks.includes('file-configuration') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={onFileConfigurationCancel}
              color='danger'>
              Cancel
            </Button>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={updateFileConfiguration}
              color='primary'>
              Save File Configuration
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
