import { Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  ArrowDownward,
  ArrowUpward,
  EditRounded,
  HistoryRounded,
} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { Modal } from '@mui/material';
import axios from 'axios';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import GridContainer from 'components/Grid/GridContainer.js';

import GridItem from 'components/Grid/GridItem.js';
import LongMenu from 'components/LongMenu/LongMenu';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import { Base64 } from 'js-base64';
import fileDownload from 'js-file-download';
import { debounce } from 'lodash';
import moment from 'moment';
import { usePostHog } from 'posthog-js/react';
import React, { useCallback, useEffect, useState, useMemo, memo } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { BUSINESS_UNITS_FEATURE_FLAG, REFUND_TYPES, utcToLocal } from 'utility';
import { modalStyle } from 'views/Clients/Claim';
import { checkPermission } from 'views/Facilities/CheckPermissions';
import { getFacilities } from 'views/Facilities/Facilities';
import loader from '../../../assets/img/loader.svg';
import Button from '../../../components/CustomButtons/Button';
import { API_BASE_URL, CLAIM_CLIENT_URL, CLIENT_URL } from '../../../config';
import IssueCheckModal from '../IssueCheckModal';
import RefundInfoHistory from '../RefundInfoHistory';
import UpdateRefundModal from '../UpdateRefundModal';
import { useStylesDanger } from './hooks';

const checkStatusOptions = ['Pending', 'Sent-uncashed', 'Voided'];
const achStatusOptions = ['Pending', 'ACH-sent', 'ACH-returned'];
const giftCardStatusOptions = ['Pending'];
const donationStatusOptions = ['Pending'];

export const REFUND_STATUSES = Object.freeze({
  UNCLAIMED: 'Unclaimed',
  REQUESTED: 'Requested',
  COMPLETED: 'Completed',
  ESCHEATED: 'Escheated',
  EXCEPTION: 'Exception',
  CANCELED: 'Canceled',
});

const paymentMethods = [
  { text: 'ACH', value: 7 },
  { text: 'Donation', value: 6 },
  { text: 'Check', value: 5 },
  { text: 'Gift Card', value: 4 },
];

const getRefundMethodString = (refundMethod) => {
  return paymentMethods.find((method) => method.value === refundMethod)?.text;
};

export const COMMUNICATION_EXCEPTIONS = Object.freeze({
  INVALID_ADDRESS: 'Invalid Address',
  UNDELIVERABLE: 'Undeliverable',
});

export default function FacilityTransaction({ role }) {
  const [payments, setPayments] = useState([]);
  const [sortBy, setSortBy] = useState({ name: 'created_at', order: -1 });
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [facility, setFacility] = useState('');
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [currentInfo, setCurrentInfo] = useState(null);
  const [originalInfo, setOriginalInfo] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [modalErr, setModalErr] = useState('');
  const [search, setSearch] = useState('');
  const [currentCheckRefund, setCurrentCheckRefund] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedForTrackChangesId, setSelectedForTrackChangesId] = useState(
    null,
  );
  const [subStatus, setSubStatus] = useState('');
  const [dateFilterType, setDateFilterType] = useState('created_at');
  const posthog = usePostHog();
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  const [transactionStatuses, setTransactionStatuses] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);
  const [payeeCategory, setPayeeCategory] = useState('');
  const [allowedRefundTypes, setAllowedRefundTypes] = useState([]);

  const isNewRefundeeFlowEnabled = posthog.isFeatureEnabled(
    'new-refundee-workflow',
  );

  const isNewInsuranceFlowEnabled = posthog.isFeatureEnabled(
    'new-data-mapping-config',
  );

  const isInsuranceEnabled = useMemo(() => {
    return (
      isNewInsuranceFlowEnabled &&
      allowedRefundTypes.includes(REFUND_TYPES.INSURANCE)
    );
  }, [isNewInsuranceFlowEnabled, allowedRefundTypes]);

  useEffect(async () => {
    const businessUnitsData = (
      await axios.get(
        API_BASE_URL + `/business-units${role === 'admin' ? '/admin/' : ''}`,
      )
    ).data;
    setBusinessUnits(businessUnitsData);
    setFilteredBusinessUnits(businessUnitsData);

    if (role === 'admin') {
      const facilities = (await getFacilities({ facility: true })).data;
      setFacilities(facilities);
    }

    if (role !== 'admin') {
      const response = await axios.get(API_BASE_URL + '/allowed_refund_types');
      setAllowedRefundTypes(response.data.allowed_refund_types);
    }
  }, []);

  const [infoHistory, setInfoHistory] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const classes = useStyles();
  const [quickFilter, setQuickFilter] = useState('');
  const dangerClass = useStylesDanger();
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const getRefunds = useCallback(
    async (clear, limit) => {
      try {
        setLoading(true);
        const refunds = await axios.post(
          API_BASE_URL +
            `/refundlist_paginated${role === 'admin' ? '_admin' : ''}`,
          {
            limit: limit ?? 25,
            offset: clear ? 0 : offSet,
            search: debouncedSearch ?? '',
            refundMethod: paymentMethod || undefined,
            facility_id: facility,
            refundStatus: status || undefined,
            orderByColumn: sortBy.name,
            orderType: sortBy.order == 1 ? 'ASC' : 'DESC',
            startDate,
            endDate,
            business_unit_id: selectedBusinessUnit,
            transactionStatus,
            subStatus,
            dateFilterType,
            additionalInfo,
            isError: quickFilter === 'Flagged Records',
            refundType: payeeCategory,
          },
        );
        setLoading(false);
        const payments = refunds.data.refunds.map((payment) => {
          payment.isChecked = false;
          payment.refund_amount = +payment.refund_amount;
          payment.refund_method_string =
            payment.refund_method == 2
              ? 'Paypal'
              : payment.refund_method == 3
              ? 'Venmo'
              : payment.refund_method == 4
              ? 'Gift Card'
              : payment.refund_method == 5
              ? 'Check'
              : payment.refund_method == 6
              ? 'Donations'
              : payment.refund_method == 7
              ? 'ACH'
              : payment.refund_method == 0
              ? 'Escheated'
              : '';
          payment.refund_status = payment.refund_status;
          return payment;
        });
        if (clear) {
          return setPayments([...payments]);
        }

        setPayments((prev) => [...prev, ...payments]);
      } catch (err) {}
    },
    [
      role,
      debouncedSearch,
      paymentMethod,
      facility,
      status,
      sortBy,
      startDate,
      endDate,
      selectedBusinessUnit,
      additionalInfo,
      quickFilter,
      subStatus,
      dateFilterType,
      transactionStatus,
      payeeCategory,
    ],
  );

  const handler = useCallback(
    debounce((searchValue) => {
      setOffSet(25);
      setDebouncedSearch(searchValue);
    }, 1000),
    [setDebouncedSearch, debouncedSearch],
  );

  const getRefundsCb = useCallback(() => {
    getRefunds(true, undefined, debouncedSearch);
  }, [getRefunds, search]);

  useEffect(() => {
    getRefundsCb();
  }, [debouncedSearch]);

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    handler(event.target.value);
  };

  const selectStatus = (event) => {
    setTransactionStatus('');
    setSubStatus('');
    setStatus(event.target.value);
    setPaymentMethod('');
  };
  /*** Change start date */
  const changeDate = (event) => {
    setStartDate(event ?? '');
  };

  /*** Change end date */
  const changeEndDate = (event) => {
    setEndDate(event ?? '');
  };

  useEffect(() => {
    getRefunds(true);
    setOffSet(25);
  }, [
    startDate,
    endDate,
    status,
    sortBy.name,
    sortBy.order,
    facility,
    paymentMethod,
    selectedBusinessUnit,
    transactionStatus,
    subStatus,
    dateFilterType,
    additionalInfo,
    payeeCategory,
    quickFilter,
  ]);

  const sortByColumn = (columnName) => {
    setSortBy({
      name: columnName,
      order:
        sortBy.name === columnName
          ? sortBy.order === 1
            ? -1
            : sortBy.order === -1
            ? 1
            : sortBy.order === 0
            ? 1
            : 1
          : 1,
    });
  };

  const sortArrow = (columnName) => {
    return columnName === sortBy.name ? (
      sortBy.order === 1 ? (
        <ArrowDownward fontSize='small' />
      ) : sortBy.order === -1 ? (
        <ArrowUpward fontSize='small' />
      ) : sortBy.order === 0 ? (
        ''
      ) : (
        ''
      )
    ) : (
      ''
    );
  };

  const onQuickFilterChange = (e) => {
    if (!e.target.value) {
      setQuickFilter('');
      setStatus('');
      setTransactionStatus('');
      setPayeeCategory('');
      setSubStatus('');
      return;
    }
    setQuickFilter(e.target.value);

    if (e.target.value === 'All Returned') {
      setStatus(REFUND_STATUSES.REQUESTED);
      setTransactionStatus('ACH-returned');
      setTransactionStatuses(achStatusOptions);
      setPaymentMethod('7');
    } else if (e.target.value === 'All Voided') {
      setStatus(REFUND_STATUSES.REQUESTED);
      setTransactionStatus('Voided');
      setTransactionStatuses(checkStatusOptions);
      setPaymentMethod('5');
    } else if (e.target.value === 'Payee Exceptions') {
      setStatus(REFUND_STATUSES.EXCEPTION);
      setSubStatus('Payee Exception');
      setPayeeCategory('insurance');
    } else if (e.target.value === 'Addenda Missing') {
      setStatus(REFUND_STATUSES.EXCEPTION);
      setSubStatus('Addenda Missing');
      setPayeeCategory('insurance');
    } else if (e.target.value === 'Invalid Address') {
      setStatus(REFUND_STATUSES.EXCEPTION);
      setSubStatus('Communication');
      setAdditionalInfo(COMMUNICATION_EXCEPTIONS.INVALID_ADDRESS);
    }
  };

  useEffect(async () => {
    if (!selectedRefund) {
      setStatusHistory([]);
      return;
    }

    setStatusHistory(
      (
        await axios.get(
          API_BASE_URL +
            `/status_history${role === 'admin' ? '' : '_facility'}/` +
            selectedRefund,
        )
      ).data,
    );
  }, [selectedRefund]);

  const copyLinkToClipBoard = (id) => {
    const encodedId = Base64.encode(id);

    if (role !== 'admin' && !isNewRefundeeFlowEnabled) {
      navigator.clipboard.writeText(
        CLIENT_URL + '/client/clientdetail/' + encodedId,
      );
    } else {
      navigator.clipboard.writeText(
        CLAIM_CLIENT_URL + '/payments/' + encodedId,
      );
    }
    toast.success('Copied to clipboard', { autoClose: 1000 });
  };
  const selectedPayments = payments.filter((payment) => payment.isChecked);

  const onInputChange = (e) => {
    if (e.target.type === 'text' || e.target.type === 'email') {
      setCurrentInfo((currentInfo) => {
        const info = { ...currentInfo };
        info[e.target.name] = e.target.value;
        return info;
      });
    }
    if (e.target.type === 'checkbox') {
      setCurrentInfo((currentInfo) => {
        const info = { ...currentInfo };
        info[e.target.name] = e.target.checked;
        return info;
      });
    }
  };
  const processPayment = async (id) => {
    const encodedId = Base64.encode(id);
    await axios
      .post(API_BASE_URL + '/add_check_facility/' + encodedId, {})
      .then((_) => {
        setCurrentCheckRefund(null);
        getRefunds(true);
      });
  };

  const getInfoHistory = async (id, refund) => {
    try {
      const result = await axios.get(
        API_BASE_URL +
          `/get_refund_info_history${role === 'admin' ? '_admin' : ''}/` +
          id,
      );
      const infoHistoryArray = [];
      let lastRefundInfoIndex;

      const lastRefundInfo = result.data
        .filter((info) => info.type === 'refund_info')
        .at(-1);

      lastRefundInfoIndex = result.data.findIndex(
        (info) => lastRefundInfo?.id === info.id,
      );
      result.data.forEach((info, index) => {
        if (info.type === 'refund_attachments') {
          const oldFiles = info.old_info ?? [];
          const newFiles = info.new_info ?? [];
          const newFilesKeys = newFiles.map((file) => file.aws_key);
          const oldFilesKeys = oldFiles.map((file) => file.aws_key);

          const filesAdded = newFiles.filter(
            (file) => !oldFilesKeys.includes(file.aws_key),
          );

          const filesRemoved = oldFiles.filter(
            (file) => !newFilesKeys.includes(file.aws_key),
          );

          infoHistoryArray.push({
            filesAdded,
            filesRemoved,
            type: info.type,
            updated_by: `${info.updated_by} on ${moment(info.created_at).format(
              'YYYY-MM-DD HH:mm:ss',
            )}`,
          });
        }

        infoHistoryArray.push({
          first_name: info.new_info.first_name,
          last_name: info.new_info.last_name,
          email: info.new_info.email,
          address: `${info.new_info.refund_to_address_line1},
${info.new_info.refund_to_address_line2},
${info.new_info.refund_to_city},
${info.new_info.refund_to_state}, ${info.new_info.refund_to_zip}.`,
          comment: info.comment ?? 'N/A',
          updated_by: `${info.updated_by} on ${moment(info.created_at).format(
            'YYYY-MM-DD HH:mm:ss',
          )}`,
          type: info.type,
          id: info.id,
          memo: info.new_info.memo,
        });

        if (lastRefundInfoIndex === index) {
          infoHistoryArray.push({
            first_name: info.old_info.first_name,
            last_name: info.old_info.last_name,
            email: info.old_info.email,
            address: `${info.old_info.refund_to_address_line1},
${info.old_info.refund_to_address_line2},
${info.old_info.refund_to_city},
${info.old_info.refund_to_state}, ${info.old_info.refund_to_zip}.`,
            comment: 'N/A',
            updated_by: 'N/A',
            type: info.type,
            id: info.id,
            memo: info.old_info.memo,
          });
        }
      });

      const refundInfoLength = infoHistoryArray.filter(
        (info) => info.type === 'refund_info',
      ).length;

      if (!refundInfoLength) {
        infoHistoryArray.push({
          first_name: refund.first_name,
          last_name: refund.last_name,
          email: refund.email,
          address: `${refund.refund_to_address_line1},
${refund.refund_to_address_line2},
${refund.refund_to_city},
${refund.refund_to_state}, ${refund.refund_to_zip}.`,
          comment: 'N/A',
          updated_by: 'N/A',
          type: 'refund_info',
          memo: refund.memo,
        });
      }

      setInfoHistory(infoHistoryArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getClass = (info, index, input) => {
    const refundInfoHistory = infoHistory.filter(
      (info) => info.type === 'refund_info',
    );

    const historyItem = infoHistory[index];

    const infoHistoryIndex = refundInfoHistory.findIndex((historyInfo) => {
      return historyInfo.id === historyItem.id;
    });

    if (infoHistoryIndex === refundInfoHistory.length - 1) {
      return classes.root;
    }

    return info[input] != refundInfoHistory[infoHistoryIndex + 1][input]
      ? dangerClass.root
      : classes.root;
  };

  const onScrollEnd = async (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - 1 <=
      e.target.clientHeight
    ) {
      setLoading(false);
      await getRefunds(false, undefined, search);
      setOffSet(offSet + 25);
    }
  };

  const downloadFile = useCallback(async () => {
    try {
      const result = await axios.get(
        API_BASE_URL +
          `/downloadReport${role === 'admin' ? '_admin' : ''}?filters=` +
          JSON.stringify({
            search: debouncedSearch,
            refundMethod: paymentMethod || undefined,
            facility_id: facility,
            refundStatus: status || undefined,
            orderByColumn: sortBy.name,
            orderType: sortBy.order == 1 ? 'ASC' : 'DESC',
            startDate,
            endDate,
            business_unit_id: selectedBusinessUnit,
            additionalInfo,
            isError: quickFilter === 'Flagged Records',
            subStatus,
            dateFilterType,
            transactionStatus,
            refundType: payeeCategory,
          }),
      );
      fileDownload(result.data, 'RefundReport.csv');
    } catch (err) {}
  }, [
    role,
    debouncedSearch,
    paymentMethod,
    facility,
    status,
    sortBy,
    startDate,
    endDate,
    selectedBusinessUnit,
    additionalInfo,
    quickFilter,
    subStatus,
    dateFilterType,
    transactionStatus,
    payeeCategory,
  ]);

  const handleClickDownload = useCallback(() => {
    toast.success('Downloading report', {
      position: 'bottom-right',
      autoClose: 5000,
    });
    downloadFile();
  }, [downloadFile]);

  const updatePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    const transactionStatusesToUse =
      e.target.value == 7
        ? achStatusOptions
        : e.target.value == 6
        ? donationStatusOptions
        : e.target.value == 4
        ? giftCardStatusOptions
        : checkStatusOptions;
    setTransactionStatuses(transactionStatusesToUse);
  };

  const statusAction = async (path, id) => {
    const alert = confirm('Are you sure you want to change the status?');
    if (!alert) return;
    await axios.get(API_BASE_URL + path + id);
    getRefunds(true);
  };

  const onFacilitySelect = (e) => {
    setFacility(e.target.value);
    setFilteredBusinessUnits(
      businessUnits.filter(
        (businessUnit) => businessUnit.facility_id == e.target.value,
      ),
    );
  };

  const toggleDelete = async (refundId) => {
    await axios.get(API_BASE_URL + `/toggle_delete/` + refundId);

    getRefunds(true);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ marginTop: '-10px' }}>
          <CardHeader
            style={{ display: 'flex', justifyContent: 'space-between' }}
            color='primary'>
            <h4>Transaction Details </h4>
          </CardHeader>

          <CardBody>
            <div
              style={{ maxHeight: '75vh', overflow: 'auto' }}
              onScroll={onScrollEnd}>
              {loading && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}

              <div className='t-header'>
                <div className='t-header-left'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={(e) => setDateFilterType(e.target.value)}>
                        <option value='created_at'>Date Processed</option>
                        <option value='status_updatedat'>Last updated</option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
                <div className='t-header-left'>
                  From:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={startDate}
                    onChange={changeDate}
                  />
                </div>
                <div className='t-header-left'>
                  To:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={endDate}
                    onChange={changeEndDate}
                  />
                </div>

                <div className='t-header-right' style={{ padding: '10px' }}>
                  <div className='custom-search-box'>
                    <input
                      type='search'
                      className='seacrh-field'
                      value={search}
                      onChange={onSearchChange}
                      placeholder='Name, Email, Check, Customer and CPD ID'
                    />
                    <span className='search-icon'>
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>
              <div className='t-header'>
                {((isNewInsuranceFlowEnabled &&
                  allowedRefundTypes.length > 1) ||
                  role === 'admin') && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={payeeCategory}
                          onChange={(e) => setPayeeCategory(e.target.value)}>
                          <option value=''>Payee Category</option>
                          <option value='regular'>Consumer Payment</option>
                          <option value='insurance'>Insurance</option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                <div className='t-header-left'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        value={status}
                        onChange={selectStatus}>
                        <option value=''>Refund Status</option>
                        {Object.values(REFUND_STATUSES).map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>

                {status === REFUND_STATUSES.UNCLAIMED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={subStatus}
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Email Sent'>Email Sent</option>
                          <option value='Communication Pending'>
                            Communication Pending
                          </option>
                          {/* <option value='Text Sent'>Text Sent</option> */}
                          <option value='Mail Sent'>Mail Sent</option>
                          <option value='Communication Undeliverable'>
                            Communication Undeliverable
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {status === REFUND_STATUSES.EXCEPTION && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={subStatus}
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Communication'>Communication</option>
                          <option value='Payee Exception'>
                            Payee Exception
                          </option>
                          <option value='Addenda Missing'>
                            Addenda Missing
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {subStatus === 'Communication' && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={additionalInfo}
                          onChange={(e) => setAdditionalInfo(e.target.value)}>
                          <option value=''>SubStatus</option>
                          {Object.values(COMMUNICATION_EXCEPTIONS).map(
                            (status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ),
                          )}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {status === REFUND_STATUSES.ESCHEATED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={subStatus}
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Dormant Pending Escheatment'>
                            Dormant Pending Escheatment
                          </option>
                          <option value='Escheatment Email Sent'>
                            Escheatment Email Sent
                          </option>
                          <option value='Escheatment Physical Mail Sent'>
                            Escheatment Physical Mail Sent
                          </option>
                          <option value='Escheatment Certified Mail Sent'>
                            Escheatment Certified Mail Sent
                          </option>
                          <option value='Escheatment NUAPA Client Review'>
                            Escheatment NUAPA Client Review
                          </option>
                          <option value='Escheated to State'>
                            Escheated to State
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {(status === REFUND_STATUSES.COMPLETED ||
                  status === REFUND_STATUSES.REQUESTED) && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={paymentMethod}
                          onChange={updatePaymentMethod}>
                          <option value=''>Payment Type</option>
                          {paymentMethods.map((method) => (
                            <option value={method.value}>{method.text}</option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {status === REFUND_STATUSES.REQUESTED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={transactionStatus}
                          onChange={(e) =>
                            setTransactionStatus(e.target.value)
                          }>
                          <option value=''>Transaction Status</option>
                          {transactionStatuses.map((method) => (
                            <option value={method}>{method}</option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {role === 'admin' && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          className='full-width-elem'
                          id='status'
                          onChange={onFacilitySelect}>
                          <option value='' style={{ fontWeight: 'bold' }}>
                            Facilities
                          </option>
                          {facilities.map((item) => {
                            return (
                              <option
                                value={item.id}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {(posthog.isFeatureEnabled(BUSINESS_UNITS_FEATURE_FLAG) ||
                  role === 'admin') && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) =>
                            setSelectedBusinessUnit(e.target.value)
                          }>
                          <option value=''>Business Unit</option>
                          {filteredBusinessUnits?.map((businessUnit) => (
                            <option
                              key={businessUnit.id}
                              value={businessUnit.id}>
                              {businessUnit.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {/* <div
                  className='t-header-right'
                  style={{
                    maxWidth: '400px',
                  }}>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='status'
                        onChange={onMethodChange}
                        value={changeMethodTo}>
                        <option value=''>Change Method To</option>
                        {changeMethodOptions.map((method) => (
                          <option value={method.value}>{method.label}</option>
                        ))}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div> */}
                <div
                  className='t-header-right'
                  style={{
                    maxWidth: '200px',
                  }}>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      {(role === 'admin' ||
                        checkPermission(
                          'transaction_page',
                          'refund_information',
                          'download',
                        )) && (
                        <Button
                          className='full-width-elem'
                          style={{
                            height: '40px',
                          }}
                          onClick={handleClickDownload}
                          color='primary'>
                          Download
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        value={quickFilter}
                        id='status'
                        className='full-width-elem'
                        onChange={onQuickFilterChange}>
                        <option value=''>Quick Filter</option>
                        <option value='All Returned'>All Returned</option>
                        <option value='All Voided'>All Voided</option>
                        {role === 'admin' && (
                          <option value='Flagged Records'>
                            Flagged Records
                          </option>
                        )}
                        {(isInsuranceEnabled || role === 'admin') && (
                          <option value='Payee Exceptions'>
                            Payee Exceptions
                          </option>
                        )}
                        {(isInsuranceEnabled || role === 'admin') && (
                          <option value='Addenda Missing'>
                            Addenda Missing
                          </option>
                        )}
                        <option value='Invalid Address'>Invalid Address</option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
              </div>
              <div>
                <div className='t-header-right'>
                  {selectedPayments.length ? (
                    <h4 style={{ margin: '-20px 0 0 0' }}>
                      Selected - <b>{selectedPayments.length}</b> ( $
                      {parseFloat(
                        selectedPayments.reduce(
                          (prev, cur) => prev + cur.refund_amount,
                          0,
                        ),
                      ).toFixed(2)}
                      )
                    </h4>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow style={{ cursor: 'pointer', userSelect: 'none' }}>
                    {/* <TableCell width='5%'>
                      <Checkbox
                        checked={isAllChecked()}
                        disabled={!payments.length}
                        onChange={onAllCheck}
                      />
                    </TableCell> */}
                    <TableCell onClick={() => sortByColumn('created_at')}>
                      Date Processed {sortArrow('created_at')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('status_updatedat')}>
                      Last Updated {sortArrow('status_updatedat')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('last_name')}>
                      Customer Information {sortArrow('last_name')}
                    </TableCell>
                    {role === 'admin' && (
                      <TableCell onClick={() => sortByColumn('facility_name')}>
                        Facility{sortArrow('facility_name')}
                      </TableCell>
                    )}
                    <TableCell onClick={() => sortByColumn('refund_amount')}>
                      Amount{sortArrow('refund_amount')}
                    </TableCell>
                    {(isNewInsuranceFlowEnabled || role === 'admin') && (
                      <TableCell onClick={() => sortByColumn('refund_type')}>
                        Payee Category{sortArrow('refund_type')}
                      </TableCell>
                    )}
                    <TableCell
                      onClick={() => sortByColumn('payee_account_number')}>
                      Customer ID / CPD ID
                      {sortArrow('payee_account_number')}
                    </TableCell>
                    {(role === 'admin' ||
                      posthog.isFeatureEnabled(
                        BUSINESS_UNITS_FEATURE_FLAG,
                      )) && (
                      <TableCell
                        onClick={() => sortByColumn('business_unit_name')}>
                        Business Unit Name/ Identifier
                        {sortArrow('business_unit_name')}
                      </TableCell>
                    )}
                    <TableCell>Payment Type / Status</TableCell>
                    <TableCell onClick={() => sortByColumn('refund_status')}>
                      Refund Status / Sub-Status
                      {sortArrow('refund_status')}
                    </TableCell>
                    <TableCell
                    // onClick={() => sortByColumn('refund_status')}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                {payments && payments.length > 0 ? (
                  <TableBody>
                    {payments.map((refund, index) => (
                      <TableRow
                        className='txtalign'
                        style={{
                          backgroundColor:
                            role === 'admin' && refund.is_deleted
                              ? '#FFFACD'
                              : (refund.is_error ||
                                  refund.transaction_is_error) &&
                                role === 'admin'
                              ? '#eaa4a4'
                              : '',
                        }}
                        key={index}>
                        {/* <TableCell>
                          {!checkIfRefundCanBeUpdated(obj) ? (
                            <Checkbox
                              onChange={() => onCheck(obj.id)}
                              checked={obj.isChecked}
                            />
                          ) : (
                            ''
                          )}
                        </TableCell> */}
                        <TableCell>
                          {utcToLocal(refund.created_at, 'MM/DD/YYYY')}
                        </TableCell>
                        <TableCell>
                          {refund.status_updatedat
                            ? utcToLocal(refund.status_updatedat, 'MM/DD/YYYY')
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {refund.first_name} {refund.last_name} {}
                          <a
                            title='Edit Customer Information'
                            onClick={() => {
                              setCurrentInfo(refund);
                              setOriginalInfo(refund);
                              setModalErr('');
                            }}>
                            <EditRounded
                              style={{ cursor: 'pointer', fontSize: '18px' }}
                              fontSize='inherit'
                            />
                          </a>
                          {!!refund.email ||
                          (refund.refund_type === 'insurance' &&
                            refund.business_payee_name) ? (
                            <span style={{ display: 'flex' }}>
                              <p style={{ fontSize: '12px' }}>
                                (
                                {refund.refund_type === 'insurance'
                                  ? refund.business_payee_name
                                  : refund.email}
                                )
                              </p>
                            </span>
                          ) : null}
                        </TableCell>
                        {role === 'admin' && (
                          <TableCell>{refund.facility_name}</TableCell>
                        )}
                        <TableCell>
                          $
                          {parseFloat(
                            refund && refund.refund_amount,
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}{' '}
                        </TableCell>
                        {(isNewInsuranceFlowEnabled || role === 'admin') && (
                          <TableCell>
                            {refund.refund_type === 'insurance'
                              ? 'Insurance'
                              : 'Consumer Payment'}
                          </TableCell>
                        )}
                        <TableCell>
                          {refund.payee_account_number || 'N/A'} <br />
                          {refund.id || 'N/A'}
                        </TableCell>
                        {(role === 'admin' ||
                          posthog.isFeatureEnabled(
                            BUSINESS_UNITS_FEATURE_FLAG,
                          )) && (
                          <TableCell>
                            {refund.business_unit_name || 'N/A'} <br />
                            {refund.business_unit_identifier || 'N/A'}
                          </TableCell>
                        )}
                        <TableCell>
                          {refund.refund_method
                            ? refund.refund_method_string
                            : 'N/A'}
                          <br />
                          <span
                            style={{
                              fontSize: '12px',
                              color:
                                refund.transaction_status === 'ACH-returned'
                                  ? '#ff0000'
                                  : '',
                            }}>
                            {refund.transaction_status || ' '}
                          </span>
                          <br />
                          <span style={{ fontSize: '12px' }}>
                            {refund?.additional_info
                              ? `(${refund?.additional_info})`
                              : ''}
                          </span>
                        </TableCell>
                        <TableCell>
                          {/* {obj && obj.payment_status} */}
                          {refund && refund.refund_status && (
                            <span>
                              <a
                                style={{
                                  color:
                                    refund.refund_status ===
                                    REFUND_STATUSES.COMPLETED
                                      ? ''
                                      : '#000000',

                                  cursor: 'auto',
                                }}>
                                {refund.refund_status
                                  ? refund.refund_status
                                  : 'N/A'}
                              </a>
                              <br />
                              <span style={{ fontSize: '12px' }}>
                                {(refund && refund.refund_sub_status) || 'N/A'}
                              </span>
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              gap: '2px',
                              alignItems: 'center',
                            }}>
                            <HistoryRounded
                              style={{ color: '#3AB795' }}
                              title='Status History'
                              onClick={() => setSelectedRefund(refund.id)}
                            />

                            <LongMenu
                              type={role}
                              handleCopyToClipboard={() =>
                                copyLinkToClipBoard(refund.id)
                              }
                              handleRefundHistory={() => {
                                setSelectedForTrackChangesId(refund.id);
                                getInfoHistory(refund.id, refund);
                              }}
                              statusAction={(path) =>
                                statusAction(
                                  `/${path}${
                                    role === 'admin' ? '_admin' : ''
                                  }/`,
                                  refund.id,
                                )
                              }
                              toggleDelete={() => toggleDelete(refund.id)}
                              refund={refund}
                              sendCheck={() => {
                                setCurrentCheckRefund(refund);
                              }}
                            />
                          </div>
                        </TableCell>
                        {/* <TableCell width="15%"> <a style={{ cursor: "pointer" }} id={obj && obj.id} onClick={(event) => deleteTransaction(event, obj && obj.facility_id, obj && obj.refund_id)} >
                          <span className="material-icons">delete</span>  </a>

                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Modal
        open={selectedRefund !== null}
        onClose={() => setSelectedRefund(null)}
        scroll='body'
        aria-labelledby='form-dialog-title'
        className='otp-dialog'
        style={{ overflow: 'visible', width: '80vw' }}>
        <Box
          sx={{ ...modalStyle, maxWidth: '80vw' }}
          style={{ maxHeight: '90vh', width: '80vw', overflow: 'visible' }}>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Status History</h4>
          </CardHeader>
          <div style={{ width: '100%', padding: '30px', overflow: 'scroll' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Updated Date
                    <br /> / Time
                  </TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Payment/ Refund Status</TableCell>
                  {role === 'admin' && <TableCell>External_id</TableCell>}
                  <TableCell>Updated By</TableCell>
                  <TableCell>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusHistory &&
                  statusHistory.map((obj, index) => (
                    <TableRow className='txtalign' key={index}>
                      <TableCell>
                        {utcToLocal(obj.created_at, 'MM-DD-YY')}
                        <br />
                        {utcToLocal(obj.created_at, 'hh:mm A')}
                      </TableCell>

                      <TableCell>
                        <div style={{ textAlign: 'center' }}>
                          {getRefundMethodString(obj.refund_method) ?? 'N/A'}{' '}
                          <br />
                          {obj.additional_info
                            ? `(${obj.additional_info})`
                            : ''}{' '}
                        </div>
                      </TableCell>
                      <TableCell>{obj.refund_status}</TableCell>
                      {role === 'admin' && (
                        <TableCell>{obj.external_id}</TableCell>
                      )}
                      <TableCell>{obj.updated_by}</TableCell>
                      <TableCell>{obj.comment}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Modal>
      {currentCheckRefund && (
        <IssueCheckModal
          currentCheckRefund={currentCheckRefund}
          setCurrentCheckRefund={setCurrentCheckRefund}
          setCurrentInfo={setCurrentInfo}
          setOriginalInfo={setOriginalInfo}
          setModalErr={setModalErr}
          processPayment={processPayment}
          role={role}
          setLoading={setLoading}
        />
      )}

      {currentInfo && (
        <UpdateRefundModal
          setModalErr={setModalErr}
          currentInfo={currentInfo}
          originalInfo={originalInfo}
          modalErr={modalErr}
          onInputChange={onInputChange}
          setCurrentInfo={setCurrentInfo}
          setOriginalInfo={setOriginalInfo}
          role={role}
          getRefunds={getRefunds}
          payments={payments}
          currentCheckRefund={currentCheckRefund}
          setCurrentCheckRefund={setCurrentCheckRefund}
        />
      )}
      {selectedForTrackChangesId && (
        <RefundInfoHistory
          selectedForTrackChangesId={selectedForTrackChangesId}
          setSelectedForTrackChangesId={setSelectedForTrackChangesId}
          infoHistory={infoHistory}
          getClass={getClass}
        />
      )}
    </GridContainer>
  );
}
