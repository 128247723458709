import { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
// @material-ui/core components
import axios from 'axios';
// core components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridsItem.js';

import logoimg from 'assets/img/imgpsh_fullsize_anim_front.png';
import ImageLoader from 'components/CustomImage/CustomImage';
import loader from '../../assets/img/loader.svg';

export default class ActivateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      encodedUser: '',
      username: '',
      password: '',
      email: '',
      otp: '',
      qrCode: '',
      errorMessage: '',
      showLoading: true,
      showSetPassword: false,
      showOTP: false,
      isLoggedIn: false,
      redirect: false,
      redirectTo: '',
      user: {},
      isLoading: false,
    };

    this.decodeToken = this.decodeToken.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  handleOtp = (event) => {
    this.setState({ otp: event.target.value });
  };

  decodeToken = async () => {
    this.setState({ isLoading: true });
    const encodedUser = this.props.match.params.encoded;
    this.state.encodedUser = encodedUser; // will be used later to validate the update password
    this.state.redirect = false;

    console.log('decode');

    try {
      const res = await axios.get(
        API_BASE_URL + `/users/activation/${encodedUser}`,
      );
      if (res.status === 200) {
        this.setState({
          username: res.data.username,
          email: res.data.email,
          showLoading: false,
          showSetPassword: true,
          isLoading: false,
        });

        localStorage.removeItem('appState');
        localStorage.removeItem('lastActivity');
      }
    } catch (err) {
      this.setState({
        errorMessage: err.response.data.message,
        isLoading: false,
      });
    }
  };

  updatePassword = async (e) => {
    e.preventDefault(); // Prevent default form submission
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('token', this.state.encodedUser);
    formData.append('password', this.state.password);

    try {
      const res = await axios.post(
        API_BASE_URL + '/users/update_password',
        formData,
      );
      console.log(res);
      debugger;
      if (res.status === 200) {
        this.setState({
          showSetPassword: false,
          redirectTo: res.data.redirectUrl,
          isLoading: false,
        });
        if (!res.data.mfaSetupRequired) {
          this.setState({ redirect: true });
        } else {
          this.setState({ showOTP: true });
          this.getOtpQrCode();
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
    }
  };

  getOtpQrCode = async () => {
    try {
      const res = await axios.get(API_BASE_URL + `/users/otp_qrcode`);
      if (res.status === 200) {
        this.setState({ qrCode: res.data.qrCode });
      }
    } catch (err) {
      console.log(err);
    }
  };

  verifyOTP = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log('verify otp');

    const formData = new FormData();
    formData.append('totp', this.state.otp);

    try {
      const res = await axios.post(
        API_BASE_URL + '/users/verify_otp',
        formData,
      );
      if (res.status === 200) {
        this.setState({ redirect: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    this.decodeToken();
  }

  render() {
    return (
      <>
        {this.state.isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
            <img src={loader} alt='Loading...' />
          </div>
        )}

        {this.state.redirect && (
          <Redirect to={this.state.redirectTo}></Redirect>
        )}
        <GridContainer>
          <GridItem xs={12} sm={10} md={6} id='10'>
            <Card>
              <CardHeader color='primary'>
                <GridContainer alignItems='center' justify='space-between'>
                  <GridItem xs={8} sm={8} md={6} className='mx-auto'>
                    <h4>Account activation</h4>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <img src={logoimg} width='130' />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {this.state.showLoading && (
                  <GridContainer>
                    <GridItem
                      xs={8}
                      sm={8}
                      md={6}
                      style={{ textAlign: 'center' }}>
                      <h3>
                        {this.state.errorMessage.length > 0 ? (
                          <b>{this.state.errorMessage}</b>
                        ) : (
                          'Loading...'
                        )}
                      </h3>
                    </GridItem>
                  </GridContainer>
                )}
                {this.state.showSetPassword && (
                  <form onSubmit={this.updatePassword}>
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <CustomInput
                          labelText='Username'
                          id='email'
                          type='email'
                          name='email'
                          value={this.state.email}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          disabled={true}
                        />
                        <CustomInput
                          labelText='Set password'
                          id='password'
                          type='password'
                          name='password'
                          onChange={this.handlePassword}
                          value={this.state.password}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <Button color='primary' type='submit'>
                          Save
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
                {this.state.showOTP && (
                  <form onSubmit={this.verifyOTP}>
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <h4>
                          Please scan the QR code with your two-factor
                          authentication (2FA) app. This will add an extra layer
                          of security to your account.
                        </h4>
                        <ImageLoader src={this.state.qrCode} alt='QR Code' />
                        <h4>Enter the OTP code generated by the app below.</h4>
                        <CustomInput
                          labelText='OTP'
                          id='otp'
                          type='text'
                          name='otp'
                          onChange={this.handleOtp}
                          value={this.state.otp}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <Button color='primary' type='submit'>
                          Send
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
