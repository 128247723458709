import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridsItem from 'components/Grid/GridsItem';
import Button from 'components/CustomButtons/Button.js';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useEffect, useState } from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import { Chip, Modal, Switch } from '@mui/material';
import { Box, MenuItem } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import { modalStyle } from 'views/Clients/Claim';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { Delete, Edit, History } from '@material-ui/icons';
import moment from 'moment';
import { getFacilities } from './Facilities';
import { Refresh } from '@mui/icons-material';

const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [modalErr, setModalErr] = useState(false);
  const [facilityUsers, setFacilityUsers] = useState([]);
  const [selectedFacilityWithId, setSelectedFacilityWithId] = useState('');
  const [inputs, setInputs] = useState({
    email: '',
    userName: '',
    mfa_type: 'NONE',
  });

  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState('');
  const [userPermissionsHistory, setUserPermissionsHistory] = useState(null);

  const [
    facilityToUpdatePermissions,
    setFacilityToUpdatePermissions,
  ] = useState('all');

  const [editUser, setEditUser] = useState(null);
  const [permissionsTemplate, setPermissionsTemplate] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const onInputChange = (e, name) => {
    setInputs({ ...inputs, [name]: e.target.value });
  };
  const openModal = () => {
    setOpen(true);
  };
  const addUser = async (e) => {
    try {
      e.preventDefault();
      await axios.post(API_BASE_URL + '/createFacilityUser', {
        email: inputs.email,
        name: inputs.userName,
        mfa_type: inputs.mfa_type,
        facility_ids: selectedFacilities.map(
          (facility) => facility.split(' - ')[0],
        ),
        permissions:
          facilityToUpdatePermissions === 'all'
            ? selectedFacilities.map((facility) => {
                const facilityId = facility.split(' - ')[0];
                return {
                  facilityId,
                  permissions: userPermissions.find(
                    (facility) => facility.facilityId === 'all',
                  )?.permissions,
                };
              })
            : userPermissions.filter(
                (facility) => facility.facilityId !== 'all',
              ),
      });

      getUsers();
      closeModal();
    } catch (err) {
      console.log(err);
      setModalErr(err.response.data.message);
    }
  };

  useEffect(async () => {
    const facilities = (await axios.get(API_BASE_URL + '/facilitiesPro')).data;
    setFacilities(facilities);
  }, []);

  const updateUser = async (e) => {
    try {
      e.preventDefault();
      await axios.post(API_BASE_URL + '/updateFacilityUser', {
        email: inputs.email,
        name: inputs.userName,
        mfa_type: inputs.mfa_type,
        facility_ids: selectedFacilities.map(
          (facility) => facility.split(' - ')[0],
        ),
        permissions:
          facilityToUpdatePermissions === 'all'
            ? selectedFacilities.map((facility) => {
                const facilityId = facility.split(' - ')[0];
                return {
                  facilityId,
                  permissions: userPermissions.find(
                    (facility) => facility.facilityId === 'all',
                  )?.permissions,
                };
              })
            : userPermissions.filter(
                (facility) => facility.facilityId !== 'all',
              ),
      });
      getUsers();
      closeModal();
    } catch (err) {
      console.log(err);
      setModalErr(err.response?.data?.message);
    }
  };

  const onSelectChange = (e) => {
    setSelectedFacilities((d) => [...d, e.target.value]);
    setSelectedFacilityWithId(e.target.value);
  };

  const deleteUser = async (id) => {
    try {
      const confirmed = confirm('Are you sure you want to delete this user?');
      if (!confirmed) return;

      await axios.delete(API_BASE_URL + '/delete_facility_user/' + id);
      getUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const toggleUser = async (id, disableUser) => {
    try {
      const alert = confirm(
        `Are you sure you want to ${
          disableUser ? 'Disable' : 'Enable'
        } this user?`,
      );

      if (!alert) return;

      await axios.put(API_BASE_URL + '/toggle_facility_user', {
        disableUser,
        id,
      });
      getUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL +
          '/allFacilityUsers/' +
          (selectedFacility === '' ? 'all' : selectedFacility),
      );

      setFacilityUsers(response.data.users ?? []);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    const facilityRes = await axios
      .get(API_BASE_URL + '/facilitiesPro')
      .then((response) => {
        return response;
      });
    getUsers();
  }, []);

  useEffect(async () => {
    setPermissionsTemplate(
      (await axios.get(API_BASE_URL + '/permissions')).data,
    );
  }, []);

  useEffect(() => {
    getUsers();
  }, [selectedFacility]);

  const onPermissionCheckboxChange = (e, parentKey, childKey, permission) => {
    setUserPermissions((allPrevUserPermissions) => {
      const prevUserPermissions =
        allPrevUserPermissions.find(
          (facility) => facility.facilityId === facilityToUpdatePermissions,
        )?.permissions ?? {};
      let updatedPermissions;
      if (e.target.checked) {
        if (childKey === 'viewPage') {
          permissionsTemplate
            .find((permissionTemplate) => permissionTemplate.key === parentKey)
            .children.forEach((child) => {
              prevUserPermissions[parentKey] = {
                ...prevUserPermissions[parentKey],
                [child.key]: child.availablePermissions,
              };
            });
        }
        updatedPermissions = {
          ...(prevUserPermissions ?? {}),
          [parentKey]: {
            ...prevUserPermissions[parentKey],
            [childKey]: prevUserPermissions?.[parentKey]?.[childKey]?.length
              ? [
                  ...new Set([
                    ...prevUserPermissions[parentKey][childKey],
                    permission,
                  ]),
                ]
              : [permission],
          },
        };
      } else {
        if (childKey === 'viewPage') {
          permissionsTemplate
            .find((permissionTemplate) => permissionTemplate.key === parentKey)
            .children.forEach((child) => {
              prevUserPermissions[parentKey] = {
                ...prevUserPermissions[parentKey],
                [child.key]: [],
              };
            });
        }
        updatedPermissions = {
          ...(prevUserPermissions ?? {}),
          [parentKey]: {
            ...prevUserPermissions[parentKey],
            [childKey]: prevUserPermissions?.[parentKey]?.[childKey]?.filter(
              (permissionString) => permissionString !== permission,
            ),
          },
        };
      }
      const newAllPermissions = allPrevUserPermissions.filter(
        (facility) => facility.facilityId !== facilityToUpdatePermissions,
      );
      return [
        ...newAllPermissions,
        {
          facilityId: facilityToUpdatePermissions,
          permissions: updatedPermissions,
        },
      ];
    });
  };

  const checkIfPermissionChecked = (parentKey, childKey, permission) => {
    const check = userPermissions
      ?.find((facility) => facility.facilityId === facilityToUpdatePermissions)
      ?.permissions?.[parentKey]?.[childKey]?.includes(permission);

    return check ?? false;
  };

  const getFacilityUserInformation = async (id) => {
    try {
      const response = (
        await axios.get(API_BASE_URL + '/getFacilityUserInformation/' + id)
      ).data;
      setEditUser(response);
      setInputs({
        email: response.userData.email,
        userName: response.userData.name,
        mfa_type: response.userData.mfa_type ?? 'NONE',
      });
      setSelectedFacilities(
        response?.facilitiesAssociated?.map(
          (facility) => facility.id + ' - ' + facility.name,
        ),
      );
      const userPermissionsData = response.facilityAndPermissions.map(
        (facilityAndPermission) => {
          return {
            facilityId: facilityAndPermission.facilityId + '',
            permissions: facilityAndPermission.permissions,
          };
        },
      );
      setUserPermissions(userPermissionsData ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserPermissionsHistory = async (id) => {
    try {
      setUserPermissionsHistory(
        (await axios.get(API_BASE_URL + '/getUserPermissionsHistory/' + id))
          .data,
      );
    } catch (err) {
      console.log(err);
    }
  };
  const closePermissionsHistoryModal = () => {
    setUserPermissionsHistory(null);
  };

  const closeModal = () => {
    setSelectedFacilityWithId('');
    setOpen(false);
    setEditUser(null);
    setModalErr('');
    setUserPermissions([]);
    setInputs({
      email: '',
      userName: '',
      mfa_type: 'NONE',
    });
    setSelectedFacilities([]);
  };

  const resendActivation = async (user) => {
    try {
      await axios.post(API_BASE_URL + '/admin/user/resend_activation_email', {
        email: user.email,
        username: user.email,
        name: user.name,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (editUser?.facilitiesAssociated?.at(0)?.id) {
      setFacilityToUpdatePermissions(
        editUser.facilitiesAssociated.at(0).id + '',
      );
    }
  }, [editUser]);

  return (
    <GridContainer>
      <GridsItem xs={12} sm={12} md={12}>
        <h2 className='subHeading'>Manage Users</h2>{' '}
      </GridsItem>
      <Card className='dashboardCard table_card_height'>
        <CardHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div className='custom-select' style={{ width: '300px' }}>
              <FormControl>
                <InputLabel id='demo-simple-select-label'>
                  Facilities
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedFacility}
                  onChange={(e) => {
                    setSelectedFacility(e.target.value);
                  }}
                  className='full-width-elem'>
                  {[
                    <MenuItem value='' key='all'>
                      All
                    </MenuItem>,
                    ...facilities?.map(
                      (obj, index) =>
                        (
                          <MenuItem value={obj.facilityID} key={obj.id}>
                            {obj.facilityName} - {obj.facilityID}
                          </MenuItem>
                        ) ?? [],
                    ),
                  ]}
                </Select>
              </FormControl>
            </div>

            <Button
              color='primary'
              onClick={openModal}
              style={{ height: '40px' }}>
              Add users
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <table width='100%' id='template' className='paymentTable'>
            <thead>
              <tr>
                <th width='5%'>S.no</th>
                <th>Email</th>
                <th>Username</th>
                <th>Facility</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {facilityUsers.map((user, index) => {
                return (
                  <tr
                    key={user.id}
                    style={{ color: user.status === 0 ? 'grey' : null }}>
                    <td>{index + 1}</td>
                    <td>{user.email}</td>
                    <td>{user.name}</td>
                    <td>{user.facility_ids}</td>
                    <td>
                      <div
                        style={{
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}>
                        <Switch
                          checked={user.status === 1 ? true : false}
                          onChange={() =>
                            toggleUser(
                              user.id,
                              user.status === 1 ? true : false,
                            )
                          }
                        />

                        <Delete
                          onClick={deleteUser.bind(null, user.id)}
                          style={{ cursor: 'pointer' }}
                        />
                        <Edit
                          onClick={() => getFacilityUserInformation(user.id)}
                          style={{ cursor: 'pointer' }}
                        />
                        <History
                          onClick={() => {
                            getUserPermissionsHistory(user.id);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
      <Modal open={(editUser ? true : false) || open} onClose={closeModal}>
        <form onSubmit={editUser ? updateUser : addUser}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              {editUser ? <h4>Edit users</h4> : <h4>Add users</h4>}
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='Email / Username'
                    onChange={(e) => onInputChange(e, 'email')}
                    value={inputs.email}
                    disabled={editUser ? true : false}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    labelText='Name'
                    onChange={(e) => onInputChange(e, 'userName')}
                    value={inputs.userName}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <div className='custom-select'>
                    <FormControl>
                      <InputLabel id='demo-simple-select-label'>
                        Facility
                      </InputLabel>

                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={selectedFacilityWithId}
                        onChange={onSelectChange}
                        className='full-width-elem'>
                        {facilities
                          .filter((facility) => {
                            return !selectedFacilities.includes(
                              facility.facilityID +
                                ' - ' +
                                facility.facilityName,
                            );
                          })
                          .map((obj, index) => [
                            <MenuItem
                              value={obj.facilityID + ' - ' + obj.facilityName}
                              key={obj.facilityID}
                              className='child'
                              style={{ textAlign: 'center' }}>
                              {obj.facilityName}
                            </MenuItem>,
                            obj.subFacilityList
                              .filter((facility) => {
                                return !selectedFacilities.includes(
                                  facility.id + ' - ' + facility.name,
                                );
                              })
                              .map((itemInner, index) => {
                                return (
                                  <MenuItem
                                    value={
                                      itemInner.id + ' - ' + itemInner.name
                                    }
                                    className='child'
                                    style={{ textAlign: 'center' }}
                                    key={itemInner.id}>
                                    --{itemInner.name}
                                  </MenuItem>
                                );
                              }),
                          ])}
                      </Select>
                    </FormControl>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className='custom-select'>
                    <FormControl>
                      <InputLabel id='mfa-type'>MFA Type</InputLabel>

                      <Select
                        labelId='mfa-type'
                        value={inputs.mfa_type}
                        onChange={(e) => onInputChange(e, 'mfa_type')}
                        name='mfa_type'
                        className='full-width-elem'>
                        <MenuItem value={'NONE'}>None</MenuItem>
                        <MenuItem value={'EMAIL_OTP'}>Email OTP</MenuItem>
                        <MenuItem value={'SOFTWARE_TOKEN_MFA'}>
                          Software MFA
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </GridItem>
                <GridItem
                  style={{ gap: '5px', display: 'flex', flexWrap: 'wrap' }}
                  xs={12}
                  sm={12}
                  md={12}>
                  {selectedFacilities.map((facility) => {
                    return (
                      <Chip
                        key={facility}
                        label={facility}
                        onDelete={() => {
                          setSelectedFacilities((d) =>
                            d.filter((f) => f !== facility),
                          );
                        }}
                      />
                    );
                  })}
                </GridItem>
                <main
                  style={{
                    padding: '20px',
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    {/* <h4>User Permissions</h4> */}
                    <div style={{ display: 'flex', gap: '5px' }}>
                      {' '}
                      <input
                        id='all'
                        type='checkbox'
                        checked={facilityToUpdatePermissions === 'all'}
                        onChange={() => setFacilityToUpdatePermissions('all')}
                      />
                      <label htmlFor='all'>
                        Apply to all selected facilities{' '}
                      </label>{' '}
                    </div>
                    <div
                      className='custom-select'
                      style={{ width: '50%', margin: 0, padding: 0 }}>
                      <FormControl>
                        <InputLabel id='demo-simple-select-label'>
                          Permissions For
                        </InputLabel>

                        <Select
                          fullWidth
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={facilityToUpdatePermissions}
                          onChange={(e) => {
                            setFacilityToUpdatePermissions(e.target.value);
                          }}
                          className='full-width-elem'>
                          {selectedFacilities.map((facility) => {
                            return (
                              <MenuItem
                                value={facility.split(' - ')[0]}
                                key={facility}
                                className='child'
                                style={{ textAlign: 'center' }}>
                                {facility.split(' - ')[1]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div style={{ height: '40vh', overflowY: 'auto' }}>
                    <table
                      style={{
                        // border: '1px solid black',
                        width: '100%',
                        borderRadius: '5px',
                      }}>
                      <thead>
                        <tr style={{ fontSize: '12px' }}>
                          <td></td>
                          <td>
                            <b>Access</b>
                          </td>
                          <td>
                            <b>Update</b>
                          </td>
                          <td>
                            <b>Download</b>
                          </td>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: '14px' }}>
                        {/* loop through permissionsTemplate */}
                        {permissionsTemplate.map((permission) => {
                          return [
                            <tr key={permission.title}>
                              <td>
                                <b>{permission.title}</b>
                              </td>
                              <td>
                                <input
                                  type='checkbox'
                                  onChange={(e) =>
                                    onPermissionCheckboxChange(
                                      e,
                                      permission.key,
                                      'viewPage',
                                      'read',
                                    )
                                  }
                                  checked={checkIfPermissionChecked(
                                    permission.key,
                                    'viewPage',
                                    'read',
                                  )}
                                />
                              </td>
                              <td></td>
                              <td></td>
                            </tr>,
                            ...permission.children.map((child, index) => {
                              return (
                                <tr key={index}>
                                  {[
                                    <td
                                      style={{ paddingLeft: '20px' }}
                                      key={child.title}>
                                      {child.title}
                                    </td>,
                                    ...['read', 'write', 'download'].map(
                                      (permissionString) => (
                                        <td
                                          key={
                                            permissionString + permission.title
                                          }>
                                          <input
                                            type='checkbox'
                                            onChange={(e) =>
                                              onPermissionCheckboxChange(
                                                e,
                                                permission.key,
                                                child.key,
                                                permissionString,
                                              )
                                            }
                                            checked={checkIfPermissionChecked(
                                              permission.key,
                                              child.key,
                                              permissionString,
                                            )}
                                            disabled={
                                              !child.availablePermissions.includes(
                                                permissionString,
                                              ) ||
                                              !(
                                                userPermissions
                                                  ?.find(
                                                    (facility) =>
                                                      facility.facilityId ===
                                                      facilityToUpdatePermissions,
                                                  )
                                                  ?.permissions?.[
                                                    permission.key
                                                  ]?.['viewPage']?.includes(
                                                    'read',
                                                  ) ?? false
                                              )
                                            }
                                          />
                                        </td>
                                      ),
                                    ),
                                  ]}
                                </tr>
                              );
                            }),
                          ];
                        })}
                      </tbody>
                    </table>
                  </div>
                </main>
              </GridContainer>
              <div>
                {!!editUser && (
                  <Button
                    variant='contained'
                    color='warning'
                    type='button'
                    onClick={() => resendActivation(editUser.userData)}>
                    Resend activation email
                  </Button>
                )}
                <Button variant='contained' color='primary' type='submit'>
                  {editUser ? 'Update' : 'Add'}
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
      <PermissionsHistoryModal
        userPermissionsHistory={userPermissionsHistory}
        closeModal={closePermissionsHistoryModal}
        permissions={permissionsTemplate}
      />
    </GridContainer>
  );
};

export default UserManagement;

const PermissionsHistoryModal = ({
  userPermissionsHistory,
  closeModal,
  permissions,
}) => {
  const [selectedFacility, setSelectedFacility] = useState();

  useEffect(() => {
    setSelectedFacility(userPermissionsHistory?.[0]?.facilityId);
  }, [userPermissionsHistory]);

  return (
    <Modal open={userPermissionsHistory ? true : false} onClose={closeModal}>
      <Box
        sx={modalStyle}
        className='makeStyles-card-96'
        noValidate
        autoComplete='off'>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <h4>Permissions History</h4>
        </CardHeader>
        <CardBody style={{ marginTop: '-20px' }}>
          <div
            className='custom-select'
            style={{ width: '50%', margin: 0, padding: 0 }}>
            <FormControl>
              <InputLabel id='demo-simple-select-label'>
                Permissions For
              </InputLabel>

              <Select
                fullWidth
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedFacility}
                onChange={(e) => {
                  setSelectedFacility(e.target.value);
                }}
                className='full-width-elem'>
                {userPermissionsHistory?.map((facility) => {
                  return (
                    <MenuItem
                      value={facility.facilityId}
                      key={facility.facilityId}
                      className='child'
                      style={{ textAlign: 'center' }}>
                      {facility.facilityName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              height: '40vh',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
              gap: '10px',
            }}>
            {userPermissionsHistory
              ?.find((facility) => facility.facilityId === selectedFacility)
              ?.updates?.map((update, index) => {
                return (
                  <div key={index}>
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#3AB795',
                        fontWeight: 'bold',
                      }}>
                      Updated by {update.updatedBy} at{' '}
                      {moment(update.updatedAt).format('MM-DD-YYYY hh:mm:ss')}
                    </p>
                    {['added', 'removed'].map((key) => {
                      return (
                        <div key={key}>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                            }}>
                            {Object.keys(update.permissionsUpdates[key]).length
                              ? key.toUpperCase() + ' PERMISSIONS'
                              : ''}
                          </p>
                          {update.permissionsUpdates[key].map((permission) => {
                            return (
                              <div key={permission}>
                                <p>
                                  {Object.keys(permission).map((parentKey) => {
                                    return (
                                      <div>
                                        <p
                                          style={{
                                            fontSize: '15px',
                                          }}>
                                          {
                                            permissions.find(
                                              (templatePermission) => {
                                                return (
                                                  templatePermission.key ===
                                                  parentKey
                                                );
                                              },
                                            )?.title
                                          }
                                        </p>

                                        {Object.keys(permission[parentKey]).map(
                                          (childKey) => {
                                            return (
                                              <div
                                                key={childKey}
                                                style={{
                                                  paddingLeft: '20px',
                                                  fontSize: '13px',
                                                }}>
                                                {childKey === 'viewPage' ? (
                                                  'View Page - '
                                                ) : (
                                                  <span>
                                                    {permissions
                                                      .find(
                                                        (
                                                          templatePermission,
                                                        ) => {
                                                          return (
                                                            templatePermission.key ===
                                                            parentKey
                                                          );
                                                        },
                                                      )
                                                      ?.children.find(
                                                        (child) =>
                                                          child.key ===
                                                          childKey,
                                                      )?.title + ' - '}
                                                  </span>
                                                )}
                                                <span>
                                                  {permission[parentKey][
                                                    childKey
                                                  ]?.join(', ')}
                                                </span>
                                              </div>
                                            );
                                          },
                                        )}
                                      </div>
                                    );
                                  })}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </CardBody>
      </Box>
    </Modal>
  );
};
