import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import { API_BASE_URL, API_BASE_IMG_URL, CLAIM_CLIENT_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from 'components/Grid/GridContainer.js';
// import Table from "components/Table/Table.js";
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import loader from '../../assets/img/loader.svg';

// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import Button from '../../components/CustomButtons/Button';
import SearchIcon from '@material-ui/icons/Search';

// react-CSV downloader
import { CSVLink } from 'react-csv';
import { Checkbox, Dialog, Modal, TextField } from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  EditRounded,
  HistoryRounded,
  LinkRounded,
  TrackChangesRounded,
} from '@material-ui/icons';
import { Base64 } from 'js-base64';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import { modalStyle } from 'views/Clients/Claim';
import CustomInput from 'components/CustomInput/CustomInput';
import { labelStyle } from 'views/Managefile/EmailTemplate';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import moment from 'moment';
import { useStylesDanger } from 'views/Clients/FacilityTransaction/hooks';
import { utcToLocal } from 'utility';
import { REFUND_STATUSES } from 'views/Clients/FacilityTransaction';
import { COMMUNICATION_EXCEPTIONS } from 'views/Clients/FacilityTransaction';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const statusOptions = [
  'Completed',
  'Pending-sent',
  'Escheated',
  'Document-sent',
  'Pending',
  'Not-initiated',
  'ACH-sent',
  'Pending-pp-sent',
  'ACH-returned',
  'Voided',
];
const statusChangeToOptions = [
  'Completed',
  'Pending-sent',
  'Escheated',
  'Document-sent',
  'Pending',
  'ACH-sent',
  'Pending-pp-sent',
  'Uninitiated',
  'ACH-returned',
  'Voided',
];

const checkStatusOptions = ['Completed', 'Pending', 'Sent-uncashed', 'Voided'];

const achStatusOptions = ['Pending', 'Completed', 'ACH-sent', 'ACH-returned'];

const giftCardStatusOptions = ['Pending', 'Completed'];

const donationStatusOptions = ['Pending', 'Completed'];

const paymentMethods = [
  { text: 'ACH', value: 7 },
  { text: 'Donation', value: 6 },
  { text: 'Check', value: 5 },
  { text: 'Gift Card', value: 4 },
];

export default function Payments() {
  const [facilities, setFacilities] = useState([]);
  const [payments, setPayments] = useState([]);
  const [sortBy, setSortBy] = useState({ name: 'created_at', order: -1 });

  const [loaded, setLoaded] = useState(false);

  const [facilityID, setFacilityID] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [facility, setFacility] = useState('');
  const [changeStatusTo, setChangeStatusTo] = useState('');
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [statusHistory, setStatus_history] = useState([]);
  const [sortedPayments, setSortedPayments] = useState([]);
  const [updateEmailOfRefund, setUpdateEmailOfRefund] = useState(null);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [modalErr, setModalErr] = useState('');
  const [search, setSearch] = useState('');
  const [availableStatus, setAvailableStatus] = useState(statusChangeToOptions);
  const [changeMethodTo, setChangeMethodTo] = useState();
  const [currentInfo, setCurrentInfo] = useState(null);
  const [originalInfo, setOriginalInfo] = useState(null);
  const dangerClass = useStylesDanger();
  const [selectedForTrackChangesId, setSelectedForTrackChangesId] = useState(
    null,
  );
  const [currentCheckRefund, setCurrentCheckRefund] = useState(null);
  const [offSet, setOffSet] = useState(0);
  const [infoHistory, setInfoHistory] = useState([]);
  const [subStatus, setSubStatus] = useState('');
  const [dateFilterType, setDateFilterType] = useState('created_at');
  const [transactionStatuses, setTransactionStatuses] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const classes = useStyles();
  const getRefunds = (clear, limit) => {
    setLoaded(true);
    const formData = new FormData();
    // const {
    //   limit,
    //   offset,
    //   search,
    //   facility_id,
    //   refundStatus,
    //   refund_method,
    //   orderByColumn,
    //   orderType,
    // } = req.body;
    axios
      .post(API_BASE_URL + '/refundlist_paginated_admin', {
        limit: limit ?? 25,
        offset: clear ? 0 : offSet,
        search: search,
        facility_id: facility,
        refundStatus: status || undefined,
        orderByColumn: sortBy.name,
        orderType: sortBy.order == 1 ? 'ASC' : 'DESC',
        startDate,
        endDate,
        dateFilterType,
        transactionStatus,
        subStatus,
        additionalInfo,
      })
      .then((refunds) => {
        setLoaded(false);
        const payments = refunds.data.refunds.map((payment) => {
          payment.isChecked = false;
          payment.refund_amount = +payment.refund_amount;
          payment.refund_method_string =
            payment.refund_method == 2
              ? 'Paypal'
              : payment.refund_method == 3
              ? 'Venmo'
              : payment.refund_method == 4
              ? 'Gift Card'
              : payment.refund_method == 5
              ? 'Check'
              : payment.refund_method == 6
              ? 'Donations'
              : payment.refund_method == 7
              ? 'ACH'
              : payment.refund_method == 0
              ? 'Escheated'
              : '-';

          payment.refund_status = payment.refund_status || '-';
          return payment;
        });
        if (clear) {
          return setPayments([...payments]);
        }

        setPayments((prev) => [...prev, ...payments]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filterOnSearch = (payments) => {
    const filteredPayments = payments.filter((payment) => {
      //use regex to match first_name last_name and email
      const regex = new RegExp(search, 'gi');
      return (
        regex.test(payment.first_name) ||
        regex.test(payment.id) ||
        regex.test(payment.last_name) ||
        regex.test(payment.email) ||
        regex.test(payment.check_num + '') ||
        regex.test(parseFloat(payment.refund_amount).toFixed(2) + '') ||
        regex.test(payment.first_name + ' ' + payment.last_name)
      );
    });
    return filteredPayments;
  };
  const sortColumn = (columnName) => {};
  const selectstatus = (event) => {
    setTransactionStatus('');
    setSubStatus('');
    setStatus(event.target.value);
  };
  /*** Change start date */
  const changeDate = (event) => {
    setStartDate(event ?? '');
  };

  /*** Change end date */
  const changeEndDate = (event) => {
    setEndDate(event ?? '');
  };

  const selectFacility = (event) => {
    setFacility(event.target.value);
  };

  useEffect(() => {
    getRefunds(true);
    setOffSet(25);
  }, [
    facility,
    startDate,
    endDate,
    status,
    sortBy.name,
    sortBy.order,
    search,
    facility,
    transactionStatus,
    subStatus,
    dateFilterType,
    transactionStatus,
    subStatus,
    dateFilterType,
    additionalInfo,
  ]);
  useEffect(() => {
    const formData = new FormData();
    formData.append('facility_id', facilityID);

    axios
      .post(API_BASE_URL + '/aggregate_report_byFacility', formData)
      .then((resdata) => {
        // console.log("resdataFacility", resdata);
        setAggregateReport(resdata.data.reportdata);
        setLoaded(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [facility]);
  // functions for pagination.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, payments.length - page * rowsPerPage);

  useEffect(() => {
    setLoaded(true);
    axios
      .get(API_BASE_URL + '/facilitiesPro')
      .then((response) => {
        return response;
      })
      .then((facility) => {
        //console.log("facilities", facility);
        setFacilities(facility && facility.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onCheck = (id) => {
    const newPayments = payments.map((payment) => {
      if (payment.id === id) {
        payment.isChecked = !payment.isChecked;
      }
      return payment;
    });
    setPayments(newPayments);
  };
  useEffect(() => {
    console.log(sortBy.order, sortBy.name);
    const array = [...payments];

    const sortedArray = array.sort((payment1, payment2) => {
      if (payment1[sortBy.name] < payment2[sortBy.name]) {
        return -1;
      }
      if (payment1[sortBy.name] > payment2[sortBy.name]) {
        return 1;
      }
      return 0;
    });

    setSortedPayments(
      filterOnSearch(
        sortBy.order === 1
          ? sortedArray
          : sortBy.order === -1
          ? sortedArray.reverse()
          : [...payments],
      ),
    );
  }, [payments, sortBy.order, sortBy.name, search]);

  const sortByColumn = (columnName) => {
    setSortBy({
      name: columnName,
      order:
        sortBy.name === columnName
          ? sortBy.order === 1
            ? -1
            : sortBy.order === -1
            ? 1
            : sortBy.order === 0
            ? 1
            : 1
          : 1,
    });
  };

  const sortArrow = (columnName) => {
    // console.log(sortBy.order);
    return columnName === sortBy.name ? (
      sortBy.order === 1 ? (
        <ArrowDownward fontSize='small' />
      ) : sortBy.order === -1 ? (
        <ArrowUpward fontSize='small' />
      ) : sortBy.order === 0 ? (
        ''
      ) : (
        ''
      )
    ) : (
      ''
    );
  };

  useEffect(async () => {
    if (!selectedRefund) {
      setStatus_history([]);
      return;
    }

    setStatus_history(
      (await axios.get(API_BASE_URL + '/status_history/' + selectedRefund))
        .data,
    );
  }, [selectedRefund]);
  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const copyLinkToClipBoard = (id) => {
    const encodedId = Base64.encode(id);
    navigator.clipboard.writeText(CLAIM_CLIENT_URL + '/payments/' + encodedId);
    toast.success('Copied to clipboard', { autoClose: 1000 });
  };
  const selectedPayments = payments.filter((payment) => payment.isChecked);

  const updateEmail = (e, editEmail) => {
    e.preventDefault();

    if (!editEmail) {
      return setModalErr('No email provided');
    }
    const formData = new FormData();
    formData.append('email', updatedEmail.toLowerCase());
    formData.append('id', editEmail);
    formData.append('zipCode', localStorage.getItem('zipCode'));
    axios
      .post(API_BASE_URL + '/updaterefundEmail/' + editEmail, formData)
      .then((res) => {
        setUpdateEmailOfRefund(null);
        getRefunds(true, payments.length);
        setUpdatedEmail('');
      })
      .catch((err) => {
        setModalErr(err.response?.data?.message);
      });
  };

  const updateInfo = (e, currentInfo, originalInfo) => {
    e.preventDefault();

    const newInfo = {
      first_name: currentInfo.first_name?.trim(),
      last_name: currentInfo.last_name?.trim(),
      email: currentInfo.email?.trim(),
      refund_to_address_line1: currentInfo.refund_to_address_line1?.trim(),
      refund_to_address_line2: currentInfo.refund_to_address_line2?.trim(),
      refund_to_city: currentInfo.refund_to_city?.trim(),
      refund_to_state: currentInfo.refund_to_state?.trim(),
      refund_to_zip: currentInfo.refund_to_zip?.trim(),
    };

    const oldInfo = {
      first_name: originalInfo.first_name,
      last_name: originalInfo.last_name,
      email: originalInfo.email,
      refund_to_address_line1: originalInfo.refund_to_address_line1,
      refund_to_address_line2: originalInfo.refund_to_address_line2,
      refund_to_city: originalInfo.refund_to_city,
      refund_to_state: originalInfo.refund_to_state,
      refund_to_zip: originalInfo.refund_to_zip,
    };

    //check if any info has changed
    let changed = false;
    for (let key in newInfo) {
      if (newInfo[key] !== oldInfo[key]) {
        changed = true;
        break;
      }
    }
    if (changed && !currentInfo.comment) {
      toast.error('Please enter a comment', { autoClose: 2000 });
      return;
    }
    console.log('update');
    axios
      .post(API_BASE_URL + '/update_refund_info_admin/' + currentInfo.id, {
        oldInfo,
        newInfo,
        comment: currentInfo.comment,
        resendCommunication: currentInfo.resendCommunication,
        id: currentInfo.id,
        changed,
      })
      .then((res) => {
        if (currentCheckRefund)
          setCurrentCheckRefund({
            ...currentCheckRefund,
            ...newInfo,
          });
        console.log('update2');
        setCurrentInfo(null);
        setOriginalInfo(null);
        getRefunds(true, payments.length);
      })
      .catch((err) => {
        console.log(err);
        setModalErr(err.response?.data?.message);
      });
  };

  const onMethodChange = (e) => {
    setChangeMethodTo(e.target.value === '' ? null : e.target.value);
    setChangeStatusTo('');
    console.log(typeof e.target.value);
    if (e.target.value == 7) {
      return setAvailableStatus([
        'Voided',
        'Pending',
        'Completed',
        'ACH-sent',
        'ACH-returned',
      ]);
    }
    if (e.target.value == 6) {
      return setAvailableStatus(['Voided', 'Completed']);
    }
    if (e.target.value == 5) {
      return setAvailableStatus([
        'Voided',
        'Pending-pp-sent',
        'Pending-sent',
        'Completed',
      ]);
    }
    if (e.target.value == 4) {
      return setAvailableStatus(['Voided', 'Pending', 'Completed']);
    }
    if (e.target.value == 99) {
      return setAvailableStatus(['Voided', 'Uninitiated']);
    }
    if (e.target.value == 0) {
      return setAvailableStatus(['Escheated']);
    }
  };

  const getClass = (info, index, input) => {
    return info.first_name &&
      index !== infoHistory.length - 1 &&
      info[input] !== infoHistory[index + 1][input]
      ? dangerClass.root
      : classes.root;
  };

  const getInfoHistory = async (id, refund) => {
    try {
      const result = await axios.get(
        API_BASE_URL + '/get_refund_info_history_admin/' + id,
      );
      const infoHistoryArray = [];

      result.data.forEach((info, index) => {
        infoHistoryArray.push({
          first_name: info.new_info.first_name,
          last_name: info.new_info.last_name,
          email: info.new_info.email,
          address: `${info.new_info.refund_to_address_line1},
${info.new_info.refund_to_address_line2},
${info.new_info.refund_to_city},
${info.new_info.refund_to_state}, ${info.new_info.refund_to_zip}.`,
          comment: info.comment,
          updated_by: `${info.updated_by} on ${moment(info.created_at).format(
            'YYYY-MM-DD HH:mm:ss',
          )}`,
        });
        if (index === result.data.length - 1) {
          infoHistoryArray.push({
            first_name: info.old_info.first_name,
            last_name: info.old_info.last_name,
            email: info.old_info.email,
            address: `${info.old_info.refund_to_address_line1},
${info.old_info.refund_to_address_line2},
${info.old_info.refund_to_city},
${info.old_info.refund_to_state}, ${info.old_info.refund_to_zip}.`,
            comment: 'N/A',
            updated_by: 'N/A',
          });
        }
      });

      if (!infoHistoryArray.length) {
        infoHistoryArray.push({
          first_name: refund.first_name,
          last_name: refund.last_name,
          email: refund.email,
          address: `${refund.refund_to_address_line1},
${refund.refund_to_address_line2},
${refund.refund_to_city},
${refund.refund_to_state}, ${refund.refund_to_zip}.`,
          comment: 'N/A',
          updated_by: 'N/A',
        });
      }

      setInfoHistory(infoHistoryArray);
    } catch (err) {
      console.log(err);
    }
  };
  const onInputChange = (e) => {
    if (e.target.type === 'text' || e.target.type === 'email') {
      setCurrentInfo((currentInfo) => {
        const info = { ...currentInfo };
        info[e.target.name] = e.target.value;
        return info;
      });
    }
    if (e.target.type === 'checkbox') {
      setCurrentInfo((currentInfo) => {
        const info = { ...currentInfo };
        info[e.target.name] = e.target.checked;
        return info;
      });
    }
  };
  const onScrollEnd = (e) => {
    // console.log(
    //   'scroll',
    //   e.target.scrollHeight - e.target.scrollTop - 1,
    //   e.target.clientHeight
    // );
    if (
      e.target.scrollHeight - e.target.scrollTop - 1 <=
      e.target.clientHeight
    ) {
      console.log('end');
      setLoaded(true);
      getRefunds(false);
      setOffSet(offSet + 25);
    }
  };

  const updatePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    const transactionStatusesToUse =
      e.target.value == 7
        ? achStatusOptions
        : e.target.value == 6
        ? donationStatusOptions
        : e.target.value == 4
        ? giftCardStatusOptions
        : checkStatusOptions;
    setTransactionStatuses(transactionStatusesToUse);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ marginTop: '-10px' }}>
          <CardHeader
            style={{ display: 'flex', justifyContent: 'space-between' }}
            color='primary'>
            <h4>Transaction Details </h4>
          </CardHeader>

          <CardBody>
            <div
              style={{ maxHeight: '75vh', overflow: 'auto' }}
              onScroll={onScrollEnd}>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div className='t-header-left'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={(e) => setDateFilterType(e.target.value)}>
                        <option value='created_at'>Processed Date</option>
                        <option value='status_updatedat'>
                          Refund Claimed Date
                        </option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
                <div className='t-header-left'>
                  From:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={startDate}
                    onChange={changeDate}
                  />
                </div>
                <div className='t-header-left'>
                  To:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={endDate}
                    onChange={changeEndDate}
                  />
                </div>

                <div className='t-header-right' style={{ padding: '10px' }}>
                  <div className='custom-search-box'>
                    <input
                      type='search'
                      className='seacrh-field'
                      value={search}
                      onChange={onSearchChange}
                      placeholder='Search by Name, Email and Customer Reference No'
                    />
                    <span className='search-icon'>
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>

              <div className='t-header'>
                <div className='t-header-left'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={selectstatus}>
                        <option value=''>Payment Status</option>
                        {Object.values(REFUND_STATUSES).map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>

                {status === REFUND_STATUSES.UNCLAIMED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Email Sent'>Email Sent</option>
                          <option value='Text Sent'>Text Sent</option>
                          <option value='Mail Sent'>Mail Sent</option>
                          <option value='Communication Undeliverable'>
                            Communication Undeliverable
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {status === REFUND_STATUSES.EXCEPTION && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Communication'>Communication</option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {subStatus === 'Communication' && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) => setAdditionalInfo(e.target.value)}>
                          <option value=''>SubStatus</option>
                          {Object.values(COMMUNICATION_EXCEPTIONS).map(
                            (status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ),
                          )}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {status === REFUND_STATUSES.ESCHEATED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={updatePaymentMethod}>
                          <option value=''>SubStatus</option>

                          <option value='Escheatment Email Sent'>
                            Escheatment Email Sent
                          </option>
                          <option value='Escheatment Physical Mail Sent'>
                            Escheatment Physical Mail Sent
                          </option>
                          <option value='Escheatment Certified Mail Sent'>
                            Escheatment Certified Mail Sent
                          </option>
                          <option value='Escheatment NUAPA Client Review'>
                            Escheatment NUAPA Client Review
                          </option>
                          <option value='Escheated to State'>
                            Escheated to State
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {(status === REFUND_STATUSES.COMPLETED ||
                  status === REFUND_STATUSES.REQUESTED) && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={updatePaymentMethod}>
                          <option value=''>Payment Type</option>
                          {paymentMethods.map((method) => (
                            <option value={method.value}>{method.text}</option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {status === REFUND_STATUSES.REQUESTED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) =>
                            setTransactionStatus(e.target.value)
                          }>
                          <option value=''>Transaction Status</option>
                          {transactionStatuses.map((method) => (
                            <option value={method}>{method}</option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='status'
                        onChange={selectFacility}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Facilities
                        </option>
                        {facilities.map((item) => {
                          return (
                            <>
                              <option
                                value={item.facilityID}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.facilityID}>
                                {item.facilityName}
                              </option>
                              {item.subFacilityList.length > 0 &&
                                item.subFacilityList.map((itemInner, index) => {
                                  return (
                                    <option
                                      value={itemInner.id}
                                      className='child'
                                      style={{ textAlign: 'center' }}
                                      key={itemInner.id}>
                                      --{itemInner.name}
                                    </option>
                                  );
                                })}
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow style={{ cursor: 'pointer', userSelect: 'none' }}>
                    <TableCell
                      onClick={() => sortByColumn('created_at')}
                      width='15%'>
                      Processed Date {sortArrow('created_at')}
                    </TableCell>
                    <TableCell
                      onClick={() => sortByColumn('facility_name')}
                      width='15%'>
                      Facility {sortArrow('facility_name')}
                    </TableCell>
                    <TableCell
                      onClick={() => sortByColumn('last_name')}
                      width='23%'>
                      Customer {sortArrow('last_name')}
                    </TableCell>
                    <TableCell
                      onClick={() => sortByColumn('refund_amount')}
                      width='15%'>
                      Amount (
                      {'$' +
                        parseFloat(
                          payments.reduce(
                            (prev, payment) => prev + +payment.refund_amount,
                            0,
                          ),
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      ){sortArrow('refund_amount')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('id')} width='15%'>
                      Vendor ID / <br /> CPD ID
                      {sortArrow('id')}
                    </TableCell>
                    <TableCell width='15%'>Payment Type </TableCell>
                    <TableCell
                      onClick={() => sortByColumn('refund_status')}
                      width='15%'>
                      Payment Status {sortArrow('refund_status')}
                    </TableCell>
                    <TableCell
                      // onClick={() => sortByColumn('refund_status')}
                      width='2%'>
                      Refund Link
                    </TableCell>
                  </TableRow>
                </TableHead>
                {payments && payments.length > 0 ? (
                  <TableBody>
                    {payments.map((obj, index) => (
                      <TableRow className='txtalign' key={index}>
                        <TableCell>
                          {obj && obj.status_updatedat
                            ? utcToLocal(
                                obj.status_updatedat,
                                'MM-DD-YYYY:hh:mm A',
                              )
                            : ''}
                        </TableCell>
                        <TableCell width='15%'>
                          {obj && obj.facility_name}
                        </TableCell>
                        <TableCell>
                          {obj.first_name} {obj.last_name}
                          <a
                            title='Edit Customer Information'
                            onClick={() => {
                              setCurrentInfo(obj);
                              setOriginalInfo(obj);
                              setModalErr('');
                            }}>
                            <EditRounded
                              style={{ cursor: 'pointer', fontSize: '18px' }}
                              fontSize='inherit'
                            />
                          </a>
                          {obj.email ? (
                            <span style={{ display: 'flex' }}>
                              <p style={{ fontSize: '12px' }}>({obj.email})</p>{' '}
                            </span>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell>
                          $
                          {parseFloat(obj && obj.refund_amount).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            },
                          )}{' '}
                        </TableCell>
                        <TableCell>
                          {obj.refund_transaction_id || 'N/A'} <br />
                          {obj.id || 'N/A'}
                        </TableCell>
                        <TableCell>
                          {obj.refund_method_string} <br />
                          {obj?.additional_info ? (
                            <small>({obj?.additional_info})</small>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell>
                          {/* {obj && obj.payment_status} */}
                          {obj && obj.refund_status && (
                            <a
                              style={{
                                cursor: 'pointer',
                                color:
                                  obj && obj.refund_status == 'Completed'
                                    ? ''
                                    : '#ff0000',
                              }}>
                              {obj && obj.refund_status}
                            </a>
                          )}
                        </TableCell>
                        <TableCell>
                          <a onClick={() => copyLinkToClipBoard(obj.id)}>
                            <LinkRounded />
                          </a>

                          <a onClick={() => setSelectedRefund(obj.id)}>
                            <HistoryRounded />
                          </a>
                          <a
                            title='Customer Information Update History'
                            onClick={() => {
                              setSelectedForTrackChangesId(obj.id);
                              getInfoHistory(obj.id, obj);
                            }}>
                            <TrackChangesRounded />
                          </a>
                        </TableCell>
                        {/* <TableCell width="15%"> <a style={{ cursor: "pointer" }} id={obj && obj.id} onClick={(event) => deleteTransaction(event, obj && obj.facility_id, obj && obj.refund_id)} >
                          <span className="material-icons">delete</span>  </a>

                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {/* <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={payments.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter> */}
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        open={selectedRefund !== null}
        maxWidth='md'
        onClose={() => setSelectedRefund(null)}
        scroll='body'
        aria-labelledby='form-dialog-title'
        className='otp-dialog'
        style={{ overflow: 'visible' }}>
        <Box style={{ maxHeight: '90vh', overflow: 'scroll' }}>
          <CardHeader
            style={{ position: 'absolute', width: '96%' }}
            color='primary'>
            <h4>Status History</h4>
          </CardHeader>
          <div style={{ width: '800px', padding: '30px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width='15%'>Updated_at</TableCell>
                  <TableCell width='15%'>Payment Type</TableCell>
                  <TableCell width='15%'>Transaction Id</TableCell>
                  <TableCell width='15%'>Payment Status</TableCell>
                  <TableCell width='15%'>Updated By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusHistory &&
                  statusHistory.map((obj, index) => (
                    <TableRow className='txtalign' key={index}>
                      <TableCell>
                        {obj && obj.created_at
                          ? moment
                              .utc(obj.created_at)
                              .local()
                              .format('MM-DD-YYYY:hh:mm A')
                          : ''}
                      </TableCell>

                      <TableCell>
                        <div style={{ textAlign: 'center' }}>
                          {obj.refund_method_string ?? 'N/A'} <br />
                          {obj.additional_info
                            ? `(${obj.additional_info})`
                            : ''}{' '}
                        </div>
                      </TableCell>
                      <TableCell>{obj.transaction_id ?? 'N/a'}</TableCell>
                      <TableCell>
                        {obj.refund_status || 'Uninitiated'}
                      </TableCell>
                      <TableCell>{obj && obj.changed_by}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Dialog>
      <Modal
        open={updateEmailOfRefund ? true : false}
        onClose={() => setUpdateEmailOfRefund(null)}>
        <form onSubmit={(e) => updateEmail(e, updateEmailOfRefund?.id)}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Enter Your Email</h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='Email'
                    value={updateEmailOfRefund?.email}
                    autoComplete='off'
                    disabled
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='New Email'
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                    value={updatedEmail}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div>
                <Button variant='contained' color='primary' type='submit'>
                  Update
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
      <Modal
        open={selectedForTrackChangesId ? true : false}
        onClose={() => {
          setSelectedForTrackChangesId(null);
        }}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Customer Information Update History</h4>
          </CardHeader>
          <CardBody style={{ marginTop: '-40px', overflowY: 'scroll' }}>
            {infoHistory.map((info, index) => {
              return (
                <GridContainer>
                  <GridItem
                    style={{ marginTop: '20px' }}
                    xs={12}
                    sm={12}
                    md={12}>
                    {index === 0 && (
                      <h5
                        style={{
                          background: '#3AB795',
                          padding: '5px',
                          borderRadius: '5px',
                          display: 'inline',
                          color: '#fff',
                          width: 'fit-content',
                          fontSize: '14px',
                          margin: 0,
                        }}>
                        Latest
                      </h5>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '10px 0',
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        borderRadius: '5px',
                        padding: '10px',
                      }}>
                      <p style={{ fontSize: '14px' }}>
                        <b>Updated By :</b> {info.updated_by}
                      </p>
                      <p style={{ fontSize: '12px', marginTop: '-5px' }}>
                        <b>Comment : </b> {info.comment}
                      </p>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>First Name</label>

                      <TextField
                        variant='outlined'
                        className={getClass(info, index, 'first_name')}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={2}
                        value={info.first_name}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Last Name</label>
                      <TextField
                        variant='outlined'
                        id='component-error'
                        className={getClass(info, index, 'last_name')}
                        // color='danger'
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={2}
                        value={info.last_name}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>
                  <GridItem
                    style={{ marginTop: '20px' }}
                    xs={12}
                    sm={12}
                    md={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Email</label>
                      <TextField
                        variant='outlined'
                        className={getClass(info, index, 'email')}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={1}
                        value={info.email}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>

                  <GridItem
                    style={{ marginTop: '20px' }}
                    xs={12}
                    sm={12}
                    md={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Address</label>
                      <TextField
                        variant='outlined'
                        className={getClass(info, index, 'address')}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={4}
                        maxRows={6}
                        value={info.address}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              );
            })}
          </CardBody>
        </Box>
      </Modal>
      <Modal
        open={currentInfo ? true : false}
        onClose={() => {
          setCurrentInfo(null);
          setOriginalInfo(null);
        }}>
        <form onSubmit={(e) => updateInfo(e, currentInfo, originalInfo)}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Update Refund Info </h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    name='email'
                    labelText='Email'
                    value={currentInfo ? currentInfo['email'] : ''}
                    autoComplete='off'
                    onChange={onInputChange}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='first_name'
                    labelText='First Name'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['first_name'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='last_name'
                    labelText='Last Name'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['last_name'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_address_line1'
                    labelText='Address Line 1'
                    onChange={onInputChange}
                    value={
                      currentInfo ? currentInfo['refund_to_address_line1'] : ''
                    }
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_address_line2'
                    labelText='Address Line 2'
                    onChange={onInputChange}
                    value={
                      currentInfo ? currentInfo['refund_to_address_line2'] : ''
                    }
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_city'
                    labelText='City'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['refund_to_city'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_state'
                    labelText='State'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['refund_to_state'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_zip'
                    labelText='Zip Code'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['refund_to_zip'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='comment'
                    labelText='Comment'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['comment'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                  }}>
                  <input
                    type='checkbox'
                    name='resendCommunication'
                    labelText=''
                    onChange={onInputChange}
                    value={
                      currentInfo ? currentInfo['resendCommunication'] : ''
                    }
                  />
                  <label>Resend Email</label>
                </div>
                <Button variant='contained' color='primary' type='submit'>
                  Update
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
    </GridContainer>
  );
}
