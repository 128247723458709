import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LinkRounded, RestartAlt } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  Block,
  Cancel,
  ContactMail,
  Delete,
  History,
  HistoryRounded,
  Payment,
  RestoreFromTrash,
} from '@material-ui/icons';
import { checkPermission } from 'views/Facilities/CheckPermissions';
import { REFUND_TYPES } from 'utility';

const ITEM_HEIGHT = 48;

export default function LongMenu({
  handleCopyToClipboard,
  statusAction,
  handleRefundHistory,
  sendCheck,
  toggleDelete,
  refund,
  type = 'facility',
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 10,
            width: '250px',
          },
        }}>
        {refund.refund_type === REFUND_TYPES.REGULAR && (
          <MenuItem onClick={handleCopyToClipboard}>
            <Box display='flex' gap='10px'>
              <LinkRounded style={{ color: '#3AB795' }} /> Copy Link
            </Box>
          </MenuItem>
        )}
        <MenuItem onClick={handleRefundHistory}>
          <Box display='flex' gap='10px'>
            <History style={{ color: '#3AB795' }} /> Info history
          </Box>
        </MenuItem>
        {(type === 'admin' ||
          checkPermission('transaction_page', 'void_check', 'read')) && (
          <MenuItem onClick={() => statusAction('voidCheck')}>
            <Box display='flex' gap='10px'>
              <Block style={{ color: '#3AB795' }} /> Void Check
            </Box>
          </MenuItem>
        )}
        {(type === 'admin' ||
          checkPermission('transaction_page', 'cancel_refund', 'read')) && (
          <MenuItem onClick={() => statusAction('cancelRefund')}>
            <Box display='flex' gap='10px'>
              <Cancel style={{ color: '#3AB795' }} /> Cancel Refund
            </Box>
          </MenuItem>
        )}
        {(type === 'admin' ||
          checkPermission(
            'transaction_page',
            'move_to_ach_returned',
            'read',
          )) && (
          <MenuItem onClick={() => statusAction('moveToAchReturned')}>
            <Box display='flex' gap='10px'>
              <RestartAlt style={{ color: '#3AB795' }} />
              Move To ACH Returned
            </Box>
          </MenuItem>
        )}
        {(type === 'admin' ||
          checkPermission('transaction_page', 'move_to_unclaimed', 'read')) && (
          <MenuItem onClick={() => statusAction('moveToUnclaimed')}>
            <Box display='flex' gap='10px'>
              <RestartAlt style={{ color: '#3AB795' }} />
              Move To Unclaimed
            </Box>
          </MenuItem>
        )}
        {type === 'admin' && (
          <MenuItem onClick={toggleDelete}>
            <Box display='flex' gap='10px'>
              {refund.is_deleted ? (
                <RestoreFromTrash style={{ color: '#3AB795' }} />
              ) : (
                <Delete style={{ color: '#3AB795' }} />
              )}
              {refund.is_deleted ? 'Restore' : 'Delete'}
            </Box>
          </MenuItem>
        )}
        {type !== 'admin' &&
          checkPermission('transaction_page', 'send_payment', 'read') && (
            <MenuItem onClick={sendCheck}>
              <Box display='flex' gap='10px'>
                <Payment style={{ color: '#3AB795' }} />
                Send Check
              </Box>
            </MenuItem>
          )}
        {type === 'admin' && (
          <MenuItem onClick={() => statusAction('mark_check_completed')}>
            <Box display='flex' gap='10px'>
              <Payment style={{ color: '#3AB795' }} />
              Mark Check Completed
            </Box>
          </MenuItem>
        )}
        {type === 'admin' && (
          <MenuItem onClick={() => statusAction('retry_transaction')}>
            <Box display='flex' gap='10px'>
              <RestartAlt style={{ color: '#3AB795' }} />
              Retry Transaction
            </Box>
          </MenuItem>
        )}
        {type === 'admin' && (
          <MenuItem onClick={() => statusAction('retry_communication')}>
            <Box display='flex' gap='10px'>
              <ContactMail style={{ color: '#3AB795' }} />
              Retry Communication
            </Box>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
