import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import avatar from 'assets/img/faces/marc.jpg';
import logoimg from 'assets/img/imgpsh_fullsize_anim_front.png';
const md5 = require('md5');
const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      template_name: '',
      heading: '',
      subheading: '',
      contact_number: '',
      message: '',
      selectedTemplateimage: null,
      template_image: '',
      logo_image: '',
      selectedLogoimage: null,
      background_image: '',
      selectedBackgroundImage: null,
      redirect: false,
      dellogo: false,
      password: '',
      isLoggedIn: false,
      user: {},
      errorMsg: '',
      successMsg: '',
    };
    this.handlePassword = this.handlePassword.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleTempName = this.handleTempName.bind(this);
    this.handleHeading = this.handleHeading.bind(this);
    this.handleSubheading = this.handleSubheading.bind(this);
    this.handleContactNum = this.handleContactNum.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  deletelogo() {
    this.setState({ dellogo: true });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  forgotpassword() {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    const email = this.state.email;
    const formData = new FormData();
    formData.append('email', email.trim());
    localStorage.setItem('forgotEmail', email.trim());
    axios
      .post(API_BASE_URL + '/forgot_password', formData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //alert(res.data.msg);

          this.setState({ redirect: true });
        }
        this.setState({ errorMsg: res.data.msg });
        /* let appState = { isLoggedIn: true, user: res && res.data && res.data.data };

        localStorage["appState"] = JSON.stringify(appState);
        this.setState({redirect:true});*/
      })
      .catch((err) => {
        this.setState({ errorMsg: err.response.data?.msg });
        console.log(err.response.data.msg);
      });
  }

  /*** functions to set input values in state***/

  handleTempName(event) {
    this.setState({ template_name: event.target.value });
  }
  handleHeading(event) {
    this.setState({ heading: event.target.value });
  }
  handleSubheading(event) {
    this.setState({ subheading: event.target.value });
  }
  handleContactNum(event) {
    this.setState({ contact_number: event.target.value });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }

  onTempFileChange = (event) => {
    this.setState({ selectedTemplateimage: event.target.files[0] });
  };

  onLogoFileChange = (event) => {
    this.setState({ selectedLogoimage: event.target.files[0] });
  };

  onBackgroundFileChange = (event) => {
    this.setState({ selectedBackgroundImage: event.target.files[0] });
  };

  render() {
    console.log('redirectredirect', this.state.user);
    if (this.state.redirect) {
      return <Redirect to="/user/resetpassword" />;
    }

    return (
      <div>
        <GridContainer alignItems="center" justify="center">
          <GridItem xs={8} sm={8} md={6}>
            <Card>
              <CardHeader color="primary">
                <GridContainer alignItems="center" justify="space-between">
                  <GridItem xs={8} sm={8} md={6}>
                    <h4>Forgot Password</h4>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <img src={logoimg} width="130" />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {this.state.successMsg ? (
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'green' }}>{this.state.successMsg}</p>
                    </GridItem>
                  ) : this.state.errorMsg ? (
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red' }}>{this.state.errorMsg}</p>
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={8} sm={8} md={12}>
                    <CustomInput
                      labelText="Enter Email"
                      id="heading"
                      name="heading"
                      onChange={this.handleEmail}
                      value={this.state.email}
                      type="text"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      required
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer alignItems="center">
                  <GridItem xs={8} sm={8} md={6}>
                    <Button
                      color="primary"
                      onClick={() => this.forgotpassword()}
                    >
                      Submit
                    </Button>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <Link color="primary" to="/user/Facilitylogin">
                      Back to Login
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
